import { Suspense } from 'react';
import LoadingScreen from '~/components/loaders/LoadingScreen';
import { Outlet } from 'react-router-dom';
import queryString from 'query-string';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from '~/components/errors/ErrorBoundaryFallback';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { QueryParamProvider } from 'use-query-params';
import { SnackbarProvider } from 'notistack';
import { ModalProvider } from '~/components/modals/Modal';

const AppRoot: React.FC<{}> = () => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: queryString.parse,
      objectToSearchString: queryString.stringify,
      enableBatching: true,
    }}
  >
    <SnackbarProvider maxSnack={3} autoHideDuration={3000} anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}>
      <ModalProvider>
        <Suspense fallback={<LoadingScreen />}>
          <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </ModalProvider>
    </SnackbarProvider>
  </QueryParamProvider>
);

export default AppRoot;
