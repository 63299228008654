import { FortuneApiError } from 'fortune-client';

export const resolveErrorMessage = (
  error: { statusCode: number } | { message: string } | { errors: FortuneApiError[] } | {},
) => {
  if ('statusCode' in error && error.statusCode === 403) {
    return 'Your are not allowed to access to this page.';
  } else if ('message' in error && error.message) {
    return error.message;
  } else if ('errors' in error && error.errors[0]) {
    return error.errors[0].detail;
  }
  return 'Unexpected error';
};
