import { SortingDirection, SortingItem, useTableSorting } from '~/utils/sorting';

interface OperatorQuotesSortingItems {
  createdAt: SortingItem;
  price: SortingItem;
}

export const OperatorQuotesSortingMap: OperatorQuotesSortingItems = {
  createdAt: {
    key: 'createdAt',
    defaultDirection: SortingDirection.Desc,
  },
  price: {
    key: 'price.amount',
    defaultDirection: SortingDirection.Desc,
  },
};

export const useOperatorQuotesSorting = () => useTableSorting(OperatorQuotesSortingMap.createdAt);
