import { useQuery } from '@tanstack/react-query';
import {
  CharterRequestResource,
  FortuneClientInstance,
  OperatorCharterRequestResource,
  OperatorQuoteResource,
  OperatorResource,
} from 'fortune-client';
import useFortuneClient from '~/hooks/useFortuneClient';
import { assertIsDefined } from '~/types/typeGuards';

const QuoteFormOpCharterRequestQueryKey = {
  get: (filter: FindOperatorCharterRequest) => ['QuoteForm', 'FindOperatorCharterRequest', filter],
};

export type FindOperatorCharterRequest =
  | {
      id: OperatorCharterRequestResource['id'];
    }
  | {
      charterRequest: CharterRequestResource['id'];
      operator: OperatorResource['id'];
    };

export interface OperatorCharterRequest extends Omit<OperatorCharterRequestResource, 'links'> {
  links: Omit<OperatorCharterRequestResource['links'], 'operatorQuotes'> & {
    operatorQuotes?: OperatorQuoteResource[];
  };
}

const fetchOperatorCharterRequest = async (
  fortuneClient: FortuneClientInstance,
  filter: FindOperatorCharterRequest,
) => {
  const {
    'operator-charter-requests': [request],
  } = await fortuneClient.getOperatorCharterRequests<
    FindOperatorCharterRequest,
    {},
    OperatorCharterRequest | undefined
  >(filter, {
    include: 'operatorQuotes',
    fields: {
      airports: 'id,airportCode,displayInfo,timeZone,icao',
    },
    denormalize: true,
  });
  return request || null;
};

const useFindOperatorCharterRequest = (
  filter: FindOperatorCharterRequest,
  options: { enabled: boolean } = { enabled: true },
) => {
  const { isLoading, fortuneClient } = useFortuneClient();
  return useQuery({
    queryKey: QuoteFormOpCharterRequestQueryKey.get(filter),
    queryFn: () => {
      assertIsDefined(fortuneClient, 'Fortune client must be defined and ready');
      return fetchOperatorCharterRequest(fortuneClient, filter);
    },
    enabled: Boolean(!isLoading && options.enabled),
  });
};

export default useFindOperatorCharterRequest;
