import { ApiErrorResponse } from 'fortune-client';
import _isString from 'lodash/isString';
import _isArray from 'lodash/isArray';
import { OcrParsingError } from './ocr/parsers/base';
import useDataTestId from '~/hooks/useDataTestId';
import { Alert, AlertProps, AlertTitle, Link, SxProps } from '@mui/material';
import { isApiErrorResponse, isError } from '~/types/typeGuards';

export type OCRError = Error | ApiErrorResponse | OcrParsingError[] | undefined;

interface OcrErrorMessageProps {
  error: OCRError;
  dataTestId?: string;
  sx?: SxProps;
}

const resolveOcrError = (
  error: OCRError,
): {
  severity: AlertProps['severity'];
  errorTitle: string;
} | null => {
  if (_isString(error)) {
    return {
      severity: 'error',
      errorTitle: `We could not process this PDF file, ${error}`,
    };
  }

  if (isError(error)) {
    return {
      severity: 'error',
      errorTitle: `We could not process this PDF file, ${error.message}`,
    };
  }

  if (isApiErrorResponse(error)) {
    return {
      severity: 'error',
      errorTitle: `We could not process this PDF file, ${error.errors[0].title} - ${error.errors[0].detail}`,
    };
  }

  if (_isArray(error) && error.length && error[0] instanceof OcrParsingError) {
    return {
      severity: 'warning',
      errorTitle: `Quote partially parsed, we couldn't resolve ${error
        .map((el) => el.getContextHumanName())
        .join(', ')} data.`,
    };
  }

  return null;
};

const OcrErrorMessage: React.FC<OcrErrorMessageProps> = ({ error, dataTestId, sx }) => {
  const dt = useDataTestId(dataTestId);
  const resolvedErrorData = resolveOcrError(error);
  if (resolvedErrorData === null) return null;

  return (
    <Alert data-testid={dt()} severity={resolvedErrorData.severity} sx={sx}>
      <AlertTitle data-testid={dt('title')}>{resolvedErrorData.errorTitle}</AlertTitle>
      If you need support, please send the PDF file to{' '}
      <Link href={`mailto:syed.shahid@flyvictor.com?Subject=V-Connect - PDF can't be processed`}>
        syed.shahid@flyvictor.com
      </Link>
    </Alert>
  );
};

export default OcrErrorMessage;
