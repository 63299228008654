import { ApiErrorResponse } from 'fortune-client';
import _isObject from 'lodash/isObject';
import _isError from 'lodash/isError';
import _isArray from 'lodash/isArray';

export const isDefined = <T>(value: T | null | undefined): value is T => {
  return value !== undefined && value !== null;
};

export const assertNever = (value: never): never => {
  throw new Error(`Unhandled discriminated union member: ${JSON.stringify(value)}`);
};

export function assertIsDefined<T>(val: T | null | undefined, errorMsg: string): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    throw new Error(errorMsg);
  }
}

export function assertIsNotEmpty<T extends string>(val: T, errorMsg: string): asserts val is Exclude<T, ''> {
  if (val === '') {
    throw new Error(errorMsg);
  }
}

export const isError = (error: unknown): error is Error => {
  if (isDefined(error) && _isError(error) && 'stack' in error && 'message' in error) {
    return true;
  }

  return false;
};

export const isApiErrorResponse = (error: unknown): error is ApiErrorResponse => {
  if (isDefined(error) && _isObject(error) && 'errors' in error && _isArray(error.errors)) {
    return true;
  }

  return false;
};

export function isDocumentNode(e: EventTarget | null): e is Node {
  if (!e || !('nodeType' in e)) {
    return false;
  }
  return true;
}
