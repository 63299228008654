import { Theme } from '@emotion/react';
import { Components } from '@mui/material';

function Select(): Partial<Components<Theme>> {
  return {
    MuiSelect: {
      defaultProps: {
        size: 'small',
        color: 'secondary',
      },
    },
  };
}

export default Select;
