import { merge } from 'lodash';
import Select from './Select';
import TextField from './TextField';
import Tabs from './Tabs';
import Slider from './Slider';
import Checkbox from './Checkbox';

export default function ComponentsOverrides() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return merge(Select(), TextField(), Tabs(), Slider(), Checkbox());
}
