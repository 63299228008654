import { Chip, Stack, Typography } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import { ReactNode } from 'react';

export interface ChipOption {
  label: string | ReactNode;
  enabled: boolean;
  onDelete: () => void;
}

interface IconChipProps {
  label: string;
  Icon: SvgIconComponent;
}

export const IconChip = ({ label, Icon }: IconChipProps) => {
  return (
    <Stack direction={'row'} alignItems={'center'} spacing={1}>
      <Icon />
      <span>{label}</span>
    </Stack>
  );
};

export const getDateRangeChip = (
  deptDateRange: string | null,
  onDelete: ChipOption['onDelete'],
  defaultLabel = 'Any Date',
) => {
  let deptDateRangeLabel = defaultLabel;
  if (deptDateRange) {
    const [deptStartDate, deptEndDate] = deptDateRange.split('/');
    if (deptStartDate !== deptEndDate) {
      deptDateRangeLabel = `${deptStartDate} - ${deptEndDate}`;
    } else {
      deptDateRangeLabel = deptEndDate;
    }
  }
  return {
    label: deptDateRangeLabel,
    enabled: !!deptDateRange,
    onDelete,
  };
};

const FilterSummary = ({ chipsOptions }: { chipsOptions: (ChipOption | null)[] }) => {
  const filteredChipsOptions = chipsOptions.filter((option) => option !== null);

  filteredChipsOptions.sort((a, b) => {
    return Number(b.enabled) - Number(a.enabled);
  });

  return (
    <Stack direction="row" spacing={2} alignItems="center" flexWrap={'wrap'} useFlexGap>
      <Typography style={{ fontWeight: 600 }}>Filtered to:</Typography>
      {filteredChipsOptions.map(({ label, enabled, onDelete }, index) => {
        return (
          <Chip
            key={index}
            label={label}
            onDelete={enabled ? onDelete : undefined}
            color={enabled ? 'primary' : 'default'}
          />
        );
      })}
    </Stack>
  );
};

export default FilterSummary;
