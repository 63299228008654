import useFortuneClient from '~/hooks/useFortuneClient';
import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query';
import {
  AircraftResource,
  AirportResource,
  FortuneClientInstance,
  OperatorCharterRequestResource,
  OperatorQuoteLegResource,
  OperatorQuoteResource,
  OperatorQuoteVConnectStatus,
  PendingRequestQuoteInfo,
  VictorDate,
  VictorPrice,
} from 'fortune-client';
import { assertIsDefined } from '~/types/typeGuards';
import { formatSorting, SortingDirection } from '~/utils/sorting';
import _isObject from 'lodash/isObject';
import { OperatorQuotesSortingMap, useOperatorQuotesSorting } from '~/views/dashboard/QuotesView/hooks/sorting';
import { OperatorQuotesFilters, useOperatorQuotesFilters } from '~/views/dashboard/QuotesView/hooks/filters';
import moment from 'moment-timezone';
import useFindLoggedUserAircrafts from '~/api/useFindLoggedUserAircrafts';

export const OperatorQuotesQueryKey = {
  get: (filters: OperatorQuotesFilters, sorting: ReturnType<typeof useOperatorQuotesSorting>['sorting']) => [
    'Dashboard',
    'QuotesList',
    'quotes',
    filters,
    sorting,
  ],
};

const TIMEOUT_RETRY = 2;

export interface OperatorQuoteLeg extends Omit<OperatorQuoteLegResource, 'links'> {
  links: Omit<OperatorQuoteLegResource['links'], 'deptAirport' | 'arrAirport'> & {
    deptAirport: AirportResource;
    arrAirport: AirportResource;
  };
}

export interface OperatorQuote extends Omit<OperatorQuoteResource, 'links'> {
  links: Omit<OperatorCharterRequestResource['links'], 'aircraft' | 'operatorQuoteLegs'> & {
    aircraft?: AircraftResource;
    operatorQuoteLegs: OperatorQuoteLeg[];
  };
  quoteAvailableActions: PendingRequestQuoteInfo['availableActions'];
  quoteAcceptedAt?: VictorDate;
  quoteRejectedAt?: VictorDate;
  requestOpened: boolean;
  acceptedInfo?: {
    price: VictorPrice;
    aircraftType: string;
  };
}

interface DateFilter {
  $gte?: string;
  $lte?: string;
}

interface OperatorQuotesApiFilters {
  createdAt?: DateFilter;
  operatorQuoteLegs: {
    deptDate?: DateFilter;
    deptAirport?: string;
    arrAirport?: string;
  };
  aircraft?: { $in: string[] };
  'additionalDetails.charterRequestVid'?: string;
  vConnectStatus?: OperatorQuoteVConnectStatus;
}

const getDateFilter = (startDate: string | null, endDate: string | null): DateFilter => {
  const result: DateFilter = {};
  if (startDate) {
    result.$gte = moment.utc(startDate, 'YYYY-MM-DD').toISOString();
  }
  if (endDate) {
    result.$lte = moment.utc(endDate, 'YYYY-MM-DD').toISOString();
  }
  return result;
};

const fetchOperatorQuotes = async (
  fortuneClient: FortuneClientInstance,
  filters: OperatorQuotesFilters,
  sorting: ReturnType<typeof useOperatorQuotesSorting>['sorting'],
  aircrafts: AircraftResource[] | undefined,
  { page, pageSize }: { page: number; pageSize: number },
) => {
  const apiFilters: OperatorQuotesApiFilters = {
    operatorQuoteLegs: {},
  };
  if (filters.createdStartDate || filters.createdEndDate) {
    apiFilters.createdAt = getDateFilter(filters.createdStartDate, filters.createdEndDate);
  }
  if (filters.legStartDeptDate || filters.legEndDeptDate) {
    apiFilters.operatorQuoteLegs.deptDate = getDateFilter(filters.legStartDeptDate, filters.legEndDeptDate);
  }
  if (filters.aircraft) {
    apiFilters.aircraft = { $in: [filters.aircraft] };
  } else if (filters.aircraftType) {
    const selectedAircrafts = (aircrafts || []).filter((aircraft) => aircraft.type === filters.aircraftType);
    if (selectedAircrafts.length) {
      apiFilters.aircraft = { $in: selectedAircrafts.map((aircraft) => aircraft.id) };
    }
  }

  if (filters.legDeptAirport) {
    apiFilters.operatorQuoteLegs.deptAirport = filters.legDeptAirport.code;
  }
  if (filters.legArrAirport) {
    apiFilters.operatorQuoteLegs.arrAirport = filters.legArrAirport.code;
  }
  if (filters.requestVid) {
    apiFilters['additionalDetails.charterRequestVid'] = filters.requestVid;
  }
  if (filters.status) {
    apiFilters.vConnectStatus = filters.status as OperatorQuoteVConnectStatus;
  }

  const { 'operator-quotes': opQuotes } = await fortuneClient.getOperatorQuotes<
    OperatorQuotesApiFilters,
    {},
    OperatorQuote
  >(apiFilters, {
    include: 'aircraft,operatorQuoteLegs,operatorQuoteLegs.deptAirport,operatorQuoteLegs.arrAirport',
    denormalize: true,
    page,
    pageSize,
    extraFields: 'quoteAvailableActions,quoteAcceptedAt,quoteRejectedAt,requestOpened,acceptedInfo',
    sort: formatSorting({
      direction: sorting.direction as SortingDirection,
      sort: sorting.sort + (sorting.sort !== OperatorQuotesSortingMap.createdAt.key ? ' createdAt' : ''),
    }),
    includeMeta: true,
  });
  return {
    opQuotes: opQuotes,
    pagination: { nextPage: page + 1, prevPage: page - 1, currentPage: page, pageSize },
  };
};

const useOperatorQuotes = () => {
  const { isLoading, fortuneClient } = useFortuneClient();
  const { sorting } = useOperatorQuotesSorting();
  const filters = useOperatorQuotesFilters();
  const { data: aircrafts } = useFindLoggedUserAircrafts();

  return useInfiniteQuery({
    queryKey: OperatorQuotesQueryKey.get(filters, sorting),
    queryFn: ({ pageParam }: { pageParam: { page: number; pageSize: number } }) => {
      assertIsDefined(fortuneClient, 'FortuneClient is defined.');
      return fetchOperatorQuotes(fortuneClient, filters, sorting, aircrafts, pageParam);
    },
    initialPageParam: { page: 1, pageSize: 10 },
    placeholderData: keepPreviousData,
    getNextPageParam: ({ pagination, opQuotes }) => {
      const { nextPage, pageSize } = pagination;
      if (opQuotes.length < pageSize) return undefined;
      return { page: nextPage, pageSize };
    },
    enabled: !isLoading,
    retry: (count, err) => {
      if (_isObject(err) && 'statusCode' in err && err.statusCode === 408) {
        return count < TIMEOUT_RETRY;
      }
      return false;
    },
  });
};

export default useOperatorQuotes;
