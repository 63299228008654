import { noop } from 'lodash';
import React, { createContext, useCallback, useContext } from 'react';
import usePersistedState, { PersistedStateKeys } from '~/hooks/usePersistedState';

export interface AuthStateContextProps {
  clearAuthState: () => void;
  setAuthState: (data: { userId: string; token: string; refreshToken: string }) => void;
  isLogged: boolean;
  userId: string | null;
  refreshToken: string | null;
}

const AuthStateContext = createContext<AuthStateContextProps>({
  clearAuthState: noop,
  setAuthState: noop,
  isLogged: false,
  userId: null,
  refreshToken: null,
});

const AuthStateProvider = ({ children }: { children: React.ReactNode | React.ReactElement }) => {
  const [userId, setUserId, resetUserId] = usePersistedState<string | null>(PersistedStateKeys.VConnectUserId, null);
  const [token, setToken, resetToken] = usePersistedState<string | null>(PersistedStateKeys.VConnectToken, null);
  const [refreshToken, setRefreshToken, resetRefreshToken] = usePersistedState<string | null>(
    PersistedStateKeys.VConnectRefreshToken,
    null,
  );

  const clearAuthState = useCallback(() => {
    resetUserId();
    resetToken();
    resetRefreshToken();
  }, [resetUserId, resetToken, resetRefreshToken]);

  const setAuthState = useCallback(
    ({ userId, token, refreshToken }: { userId: string; token: string; refreshToken: string }) => {
      setUserId(userId);
      setToken(token);
      setRefreshToken(refreshToken);
    },
    [setUserId, setToken, setRefreshToken],
  );

  const authState = userId && token && refreshToken ? { userId, token, refreshToken } : null;

  return (
    <AuthStateContext.Provider
      value={{
        clearAuthState,
        setAuthState,
        isLogged: Boolean(authState),
        userId: authState?.userId || null,
        refreshToken: authState?.refreshToken || null,
      }}
    >
      {children}
    </AuthStateContext.Provider>
  );
};

const useAuthStateContext = () => useContext(AuthStateContext);

export const useAuthState = () => {
  const result = useAuthStateContext();
  return result;
};

export const useLoggedUserId = () => {
  const authState = useAuthStateContext();
  return authState.userId;
};

export default AuthStateProvider;
