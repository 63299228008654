import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import { isApiErrorResponse, isError } from '~/types/typeGuards';

type Extra = unknown;
type Extras = Record<string, Extra>;

type ErrorType = 'error' | 'api-error' | 'unknown';

export const resolveError = (error: unknown) => {
  let errorTitle = 'Something went wrong';
  let errorBody = 'An unexpected error took place. If the problem persists, please contact Victor.';
  let errorType: ErrorType = 'unknown';
  let stackTrace: string | undefined;

  if (isApiErrorResponse(error)) {
    errorTitle = error.errors[0].title;
    errorBody = error.errors[0].detail;
    errorType = 'api-error';
  }

  if (isError(error)) {
    errorBody = error.message;
    errorType = 'error';
    stackTrace = error.stack;
  }

  return {
    title: errorTitle,
    message: errorBody,
    errorType,
    stackTrace,
  };
};

const captureException = (error: unknown, { extra }: { extra?: Extras } = {}) => {
  const resolvedError = resolveError(error);
  console.log(resolvedError.title, resolvedError.message, resolvedError, extra);
  console.error(resolvedError.stackTrace ?? error);

  return resolvedError;
};

const result = { captureException };

const useExceptionLogger = () => result;

export default useExceptionLogger;
