/**
 * @typedef {Function} DataTestIdFactory
 * @param {...string} [params] List of specifiers for the current data-testid.
 * @return {string} The data-testid to apply.
 *
 * @param {string} dataTestId Custom dataTestId taken from component props.
 * @return {DataTestIdFactory}
 */

type DataTestId = string | undefined;

const dataTestIdFactory =
  (dataTestId: DataTestId) =>
  (...params: (string | number)[]) => {
    if (dataTestId) {
      if (params.length) {
        return `${dataTestId}-${params.join('-')}`;
      }
      return dataTestId;
    }
    return undefined;
  };

const useDataTestId = (dataTestId: DataTestId) => dataTestIdFactory(dataTestId);

export default useDataTestId;
