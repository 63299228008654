import React, { ReactNode } from 'react';
import { Chip, Stack, Typography } from '@mui/material';
import { AirportResource, VictorDate } from 'fortune-client';
import { ArrowForward } from '@mui/icons-material';
import dateUtils from '~/utils/dateUtils';
import './legsRoute.css';

interface RouteProps {
  displayDate: boolean;
  legs: {
    deptDate: VictorDate;
    arrDate: VictorDate;
    links: {
      deptAirport: Pick<AirportResource, 'airportCode' | 'icao'>;
      arrAirport: Pick<AirportResource, 'airportCode' | 'icao'>;
    };
  }[];
  maxAirports: number;
}

const LegsRoute: React.FC<RouteProps> = ({ legs, maxAirports, displayDate }) => {
  const sortedLegs = legs.sort(
    (a, b) => dateUtils.toDate(a.deptDate).getTime() - dateUtils.toDate(b.deptDate).getTime(),
  );
  let airports: ReactNode[] = [];
  const departAt = `${sortedLegs[0].deptDate.date} ${sortedLegs[0].deptDate.time}`;

  let arrivalAt: string | null = null;
  const lastIndex = sortedLegs.length - 1;

  if (displayDate) {
    arrivalAt = `${sortedLegs[lastIndex].arrDate.date} ${sortedLegs[0].arrDate.time}`;
  }

  sortedLegs.forEach((leg, i) => {
    const isFirstLeg = i === 0;
    const isLastLeg = i === sortedLegs.length - 1;
    airports.push(
      ...[
        <div className={isFirstLeg ? 'edgeDateWrapper' : ''} key={i}>
          {`${leg.links.deptAirport.icao || leg.links.deptAirport.airportCode}`}
        </div>,
        <div className={isLastLeg ? 'edgeDateWrapper' : ''} key={i}>
          {`${leg.links.arrAirport.icao || leg.links.arrAirport.airportCode}`}
        </div>,
      ],
    );
  });
  airports = airports.filter((airport, index) => {
    return !index || index === airports.length - 1 || airport !== airports[index - 1];
  });

  const airportNodes = airports.reduce<React.ReactNode[]>((memo, airport, index) => {
    if (index < Math.ceil(maxAirports / 2) || index > airports.length - Math.floor(maxAirports / 2) - 1) {
      memo.push(<Chip label={airport} sx={{ cursor: 'pointer' }} size="small" variant="outlined" />);
      if (index !== airports.length - 1) {
        if (index === Math.ceil(maxAirports / 2) - 1 && airports.length > maxAirports) {
          memo.push(<Typography>...</Typography>);
        } else {
          memo.push(<ArrowForward sx={{ color: (theme) => theme.palette.action.active }} />);
        }
      }
    }
    return memo;
  }, []);

  return (
    <>
      <Stack direction="row" justifyContent="space-between" gap="16px">
        {airportNodes.map((node, index) => (
          <React.Fragment key={index}>{node}</React.Fragment>
        ))}
      </Stack>
      {displayDate && (
        <Stack direction="row" justifyContent="space-between">
          <h5 className={'dateElement'}>{departAt}</h5>
          <h5 className={'dateElement'}>{arrivalAt}</h5>
        </Stack>
      )}
    </>
  );
};

export default LegsRoute;
