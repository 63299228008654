import { AircraftResource } from 'fortune-client';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import useFindLoggedUserAircrafts from '~/api/useFindLoggedUserAircrafts';
import useDataTestId from '~/hooks/useDataTestId';

export interface AircraftAutocompleteProps {
  onAircraftChanged: (aircraft: AircraftResource | null) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  value?: AircraftResource['id'] | null;
  dataTestId?: string;
}

const AircraftAutocomplete: React.FC<AircraftAutocompleteProps> = (props) => {
  const dt = useDataTestId(props.dataTestId);
  const { onAircraftChanged, error, disabled, helperText, value } = props;
  const { data: aircraftOptions, isPending } = useFindLoggedUserAircrafts();
  const selectedAircraft = (aircraftOptions || []).find((option) => option.id === value);

  const getSearchText = (option: AircraftResource) =>
    [option.type, option.registration, option.seats, 'seats'].join(' ');
  return (
    <Autocomplete<AircraftResource>
      value={selectedAircraft || null}
      options={aircraftOptions || []}
      getOptionLabel={(option) => getSearchText(option)}
      onChange={(e, val) => {
        onAircraftChanged(val || null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          disabled={disabled}
          label="Aircraft"
          inputProps={{
            ...params.inputProps,
            'data-testid': dt('input'),
          }}
          InputProps={{
            ...params.InputProps,
            startAdornment: isPending ? (
              <Box>
                <CircularProgress size={20} />
              </Box>
            ) : null,
          }}
        />
      )}
    />
  );
};

export default AircraftAutocomplete;
