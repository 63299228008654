import { VictorDate } from 'fortune-client';
import _capitalize from 'lodash/capitalize';
import moment, { Moment } from 'moment-timezone';

const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_TIME_FORMAT = 'HH:mm';

const toTimestamp = (obj: VictorDate | undefined | null) => {
  if (obj?.date) {
    const time = !obj.time ? '00:00' : obj.time;
    return new Date(`${obj.date}T${time}Z`).getTime();
  }
  return null;
};

const toObject = (date: Moment | Date | undefined): VictorDate | undefined => {
  const m = moment(date);
  if (date && m.isValid()) {
    return {
      date: m.format(DEFAULT_DATE_FORMAT),
      time: m.format(DEFAULT_TIME_FORMAT),
      timeZone: m.format('Z'),
    };
  }
};

const toLocalDate = (victorDate: VictorDate | undefined | null) => {
  if (!victorDate) return null;
  const m = moment(new Date(`${victorDate.date} ${victorDate.time ?? '00:00:00'}`));
  m.zone(victorDate.timeZone);
  if (m.format(DEFAULT_DATE_FORMAT) === 'Invalid date') return null;
  return m.toDate();
};

/**
 * Convert a victor date objet to a JS date. TImezone not handled.
 */
const toDate = (victorDate: VictorDate) => new Date(`${victorDate.date}T${victorDate.time ?? '00:00:00'}`);

/**
 * Convert a victor date objet to a moment instance.
 */
const toMoment = (victorDate: VictorDate) =>
  moment(
    [victorDate.date, victorDate.time ?? '00:00:00', victorDate.timeZone].join(' '),
    `${DEFAULT_DATE_FORMAT} ${DEFAULT_TIME_FORMAT} Z`,
  ).utcOffset(victorDate.timeZone);

/**
 * Convert a date js instance to a victor object.
 */
const toLocalObject = (date: Date, timeZone: string) => {
  const m = moment(date);
  m.tz(timeZone);
  if (m.format(DEFAULT_DATE_FORMAT) === 'Invalid date') return null;
  return {
    date: m.format(DEFAULT_DATE_FORMAT),
    time: m.format(DEFAULT_TIME_FORMAT),
    timeZone: m.format('Z'),
  };
};

/**
 * Convert a victor date object to a date string.
 */
const toDateString = (dateObj: VictorDate) => toDate(dateObj).toString();

/**
 * Convert string HH:mm to minutes value
 */
const timeToMinutes = (time: string) => {
  const parts = time.split(':');
  return parseInt(parts[0]) * 60 + parseInt(parts[1]);
};

/**
 * Convert victor date tu UTC victor date
 */
const victorDateToUtcVictorDate = (victorDate: VictorDate) => toObject(moment.utc(toMoment(victorDate)));

/**
 * Calculates an arrival time based on dep date, duration and target time zone.
 */
const calculateArrival = (
  departureTimeZone: string | null | undefined,
  depDateTime: VictorDate,
  durationInMinutes: number,
  arrivalTimeZone: string | null | undefined,
) => {
  try {
    if (!departureTimeZone || !moment.tz.zone(departureTimeZone)) throw new Error('Invalid departure timezone');
    if (!arrivalTimeZone || !moment.tz.zone(arrivalTimeZone)) throw new Error('Invalid arrival timezone');
    const departure = toMoment(depDateTime);

    if (departure.format() !== 'Invalid date') {
      const arrival = departure.tz(arrivalTimeZone);
      arrival.add('m', durationInMinutes);
      const timeZone = arrival.format('Z');
      const time = arrival.format('HH:mm');
      return {
        date: arrival.format('YYYY-MM-DD'),
        time: time,
        timeZone: timeZone,
      };
    }
  } catch {
    console.warn('invalid timezone');
  }
};

export const dateUtils = {
  toDate,
  toMoment,
  toLocalDate,
  toObject,
  toTimestamp,
  toLocalObject,
  toDateString,
  timeToMinutes,
  victorDateToUtcVictorDate,
  calculateArrival,
};

export default dateUtils;
