import { useEffect } from 'react';
import { FILTER_FORM_ID } from '~/views/dashboard/PendingRequestsView/constants';
import { useNavigate } from 'react-router-dom';

let SCROLL_TOP = 0;

const useScrollTopKeeperNavigation = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const main = document.querySelector('main');
    if (!main) return () => {};
    if (SCROLL_TOP) {
      const filter = document.querySelector(`#${FILTER_FORM_ID}`);
      main.scrollTop = SCROLL_TOP + (filter?.scrollHeight || 0);
    }
  }, []);

  const navigateTo = (url: string) => {
    if (!url) return;
    const main = document.querySelector('main');
    SCROLL_TOP = main?.scrollTop || 0;
    navigate(url);
  };

  return { navigateTo };
};

export default useScrollTopKeeperNavigation;
