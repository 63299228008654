import { TypographyOptions } from '@mui/material/styles/createTypography';
import React from 'react';

interface ExtendedTypographyOptions extends TypographyOptions {
  pill: React.CSSProperties;
}

const VConnectTypography: ExtendedTypographyOptions = {
  pill: {
    fontSize: '10px',
    fontWeight: 900,
  },
};

export default VConnectTypography;
