import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Typography } from '@mui/material';

const RootStyle = styled(Box)(({ theme }) => ({
  height: '100vh',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
}));

const LoadingScreen: React.FC<{}> = () => {
  return (
    <RootStyle>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress />{' '}
        <Typography sx={{ ml: 2 }} variant="h5">
          Loading ...
        </Typography>
      </Box>
    </RootStyle>
  );
};

export default LoadingScreen;
