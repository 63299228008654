import { useEffect, useRef } from 'react';

const usePrevious: <T>(value: T) => T | undefined = (value) => {
  const ref = useRef<typeof value>();
  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export default usePrevious;
