import { Tab, Tabs, Tooltip } from '@mui/material';
import _first from 'lodash/first';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { TabProps } from '@mui/material/Tab/Tab';
import useDataTestId from '~/hooks/useDataTestId';

interface TabData {
  label: string;
  path: string;
  tooltip?: string;
  dataTestId?: string;
}

interface Props {
  tabs: TabData[];
  dataTestId?: string;
}

function useRouteMatch(patterns: readonly string[]) {
  const { pathname, search } = useLocation();

  return patterns.find((pattern) => {
    return (
      pattern === pathname ||
      pathname.startsWith(pattern) ||
      pattern === `${pathname}${search}` ||
      matchPath(pattern, pathname)
    );
  });
}

const TooltipTab = ({ tooltip, path, label, dataTestId, ...props }: TabProps & TabData) => {
  return (
    <Tooltip title={tooltip}>
      <Tab value={path} component={Link} key={label} to={path} label={label} data-testid={dataTestId} {...props} />
    </Tooltip>
  );
};

const NavigationTabs: React.FC<Props> = ({ tabs, dataTestId }) => {
  const dt = useDataTestId(dataTestId);
  const firstTab = _first(tabs);
  const currentTab = useRouteMatch(tabs.map((tab) => tab.path)) || firstTab?.path;

  return (
    <Tabs value={currentTab} sx={{ m: 1 }}>
      {tabs.map(({ label, path, tooltip }) => (
        <TooltipTab
          value={path}
          component={Link}
          key={label}
          path={path}
          label={label}
          tooltip={tooltip}
          dataTestId={dt('tab-' + path.substring(1, path.length - 1))}
        />
      ))}
    </Tabs>
  );
};

export default NavigationTabs;
