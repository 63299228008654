import React from 'react';
import { Tooltip } from '@mui/material';
import { useSnackbar } from 'notistack';

export interface RequestVIDProps {
  vid?: string;
}

const RequestVID: React.FC<RequestVIDProps> = ({ vid }) => {
  const { enqueueSnackbar } = useSnackbar();

  const copyHandler = (toClipboard: string) => {
    navigator.clipboard.writeText(toClipboard);
    enqueueSnackbar('Copied to clipboard', {
      variant: 'success',
      autoHideDuration: 3000,
    });
  };

  if (!vid) return null;
  return (
    <Tooltip title="Click to copy" enterDelay={200}>
      <span
        style={{ cursor: 'pointer' }}
        onClick={(event) => {
          event.stopPropagation();
          copyHandler(vid);
        }}
      >
        {vid}
      </span>
    </Tooltip>
  );
};

export default RequestVID;
