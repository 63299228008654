import { useQuery } from '@tanstack/react-query';
import useFortuneClient from '../../../../hooks/useFortuneClient';
import { FortuneClientInstance, VConnectFilterResource } from 'fortune-client';
import _isEmpty from 'lodash/isEmpty';
import { assertIsDefined } from '~/types/typeGuards';

import { mapToFormFilters } from '~/views/dashboard/utils/filters';
import { PendingRequestsFilters } from '~/views/dashboard/PendingRequestsView/hooks/filters';
import { OperatorQuotesFilters } from '~/views/dashboard/QuotesView/hooks/filters';

export const FilterSettingsKey = {
  get: (source: VConnectFilterResource['source']) => ['Shared', 'LastFilterSettings', source],
};

const fetchFilterSettings = async <T extends {}>(
  fortuneClient: FortuneClientInstance,
  source: VConnectFilterResource['source'],
): Promise<Partial<T>> => {
  const { 'v-connect-filters': filters } = await fortuneClient.getVConnectFilters(
    {
      source: source === 'requests' ? null : source, // TODO remove condition, just for testing
    },
    {
      sort: '-date',
      limit: 1,
    },
  );
  if (_isEmpty(filters)) return {} as Partial<T>;

  const [filter] = filters;
  return mapToFormFilters(filter.settings);
};

export const useLastFilterSettings = <T extends {}>(source: VConnectFilterResource['source'] = 'requests') => {
  const { isLoading, fortuneClient } = useFortuneClient();

  return useQuery({
    queryKey: FilterSettingsKey.get(source),
    staleTime: Infinity,
    queryFn: () => {
      assertIsDefined(fortuneClient, 'Fortune client must be defined and ready');
      return fetchFilterSettings<T>(fortuneClient, source);
    },
    enabled: !isLoading,
  });
};

export default useLastFilterSettings;
export const useLastRequestsFilterSettings = () => useLastFilterSettings<PendingRequestsFilters>('requests');
export const useLastOperatorQuotesFilterSettings = () => useLastFilterSettings<OperatorQuotesFilters>('quotes');
