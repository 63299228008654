import { ErrorBoundary } from 'react-error-boundary';
import { noop } from 'lodash';
import { createContext, useCallback, useContext, useState } from 'react';
import { ModalErrorBoundaryFallback } from '~/components/errors/ErrorBoundaryFallback';

export interface ModalProps {
  closeModal: (param?: PromiseResolvePayload<'CLOSE'>) => void;
}

interface PromiseResolvePayload<A extends string = string> {
  action: A;
  [key: string]: unknown;
}

interface ModalContextType {
  showModal<P extends ModalProps>(options: {
    component: ((props: P) => React.ReactElement) | React.FC<P>;
    props?: Omit<P, 'closeModal'>;
  }): Promise<NonNullable<Parameters<P['closeModal']>[0]> | PromiseResolvePayload<'CLOSE'>>;
  closeModal(data?: PromiseResolvePayload): void;
}

let modalId = 1;

const ModalContext = createContext<ModalContextType>({
  showModal: () => Promise.resolve({ action: 'CLOSE' }),
  closeModal: noop,
});

export const useModal = () => useContext(ModalContext);

interface ModalProviderProps {
  children: React.ReactNode;
}

export const ModalProvider = ({ children }: ModalProviderProps) => {
  const [modals, setModals] = useState<
    {
      id: number;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      component: ((props: any) => React.ReactElement) | React.FC<any>;
      props?: Record<string, unknown>;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolve: (data: PromiseResolvePayload<any>) => void;
    }[]
  >([]);

  const showModal = useCallback<ModalContextType['showModal']>(({ component, props }) => {
    return new Promise((resolve) => {
      setModals((prevState) => [...prevState, { component, props, resolve, id: modalId++ }]);
    });
  }, []);

  const closeModal = useCallback<ModalContextType['closeModal']>(
    (data = { action: 'CLOSE' }) => {
      const lastModal = modals.length > 0 ? modals[modals.length - 1] : null;
      setModals((prevState) => prevState.slice(0, -1));
      lastModal?.resolve(data);
    },
    [modals],
  );

  return (
    <ModalContext.Provider value={{ showModal, closeModal }}>
      {children}
      {modals.map((modal) => {
        const ModalComponent = modal.component;

        return (
          <ErrorBoundary
            key={modal.id}
            fallbackRender={(props) => <ModalErrorBoundaryFallback {...props} onClose={closeModal} />}
          >
            <ModalComponent {...modal.props} closeModal={closeModal} />
          </ErrorBoundary>
        );
      })}
    </ModalContext.Provider>
  );
};
