import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Container, useTheme } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import useDataTestId from '~/hooks/useDataTestId';
import { FILTER_FORM_ID } from '~/views/dashboard/PendingRequestsView/constants';

const getWindowHeight = () => window.innerHeight;

interface FilterFormProps {
  dataTestId?: string;
  children: React.ReactNode;
  summary: React.ReactNode;
}
const FilterForm: React.FC<FilterFormProps> = ({ dataTestId, children, summary }) => {
  const dt = useDataTestId(dataTestId);
  const [expanded, setExpanded] = useState<boolean>(true);
  const theme = useTheme();
  const [isScrollHandlerEnabled, setScrollHandlerEnabled] = useState<boolean>(true);

  const handleChange = (_event: unknown, newExpanded: boolean) => {
    setScrollHandlerEnabled(false);
    setExpanded(newExpanded);
  };

  useEffect(() => {
    const main = document.querySelector('main');
    if (!main) return () => {};

    let lastScrollY = main.scrollTop;
    const updateScrollDirection = () => {
      if (main.scrollHeight <= getWindowHeight() + 330) return;
      if (!isScrollHandlerEnabled) return;

      const scrollY = main.scrollTop;

      if (scrollY === 0) setExpanded(true);
      else if (Math.abs(scrollY - lastScrollY) > 10) setExpanded(false);

      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    main.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      main.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [isScrollHandlerEnabled]);

  const handleTransitionCompleted = () => {
    setTimeout(() => setScrollHandlerEnabled(true), 100);
  };

  return (
    <Accordion
      sx={{
        position: 'sticky',
        top: theme.spacing(8),
        zIndex: 100,
        px: 1,
      }}
      disableGutters
      expanded={expanded}
      data-testid={dt('accordion')}
      onChange={handleChange}
      TransitionProps={{
        onExited: handleTransitionCompleted,
        onEntered: handleTransitionCompleted,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1c-content" id="panel1c-header">
        {summary}
      </AccordionSummary>

      <AccordionDetails id={FILTER_FORM_ID}>
        <Container>{children}</Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default FilterForm;
