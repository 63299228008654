import { useQuery } from '@tanstack/react-query';
import { useLoggedUserId } from '../providers/AuthStateProvider';
import useFortuneClient from '../hooks/useFortuneClient';
import { FortuneClientInstance, OperatorResource, UserResource, VConnectAccountResource } from 'fortune-client';
import { assertIsDefined } from '~/types/typeGuards';

const LoggedUserQueryKey = ['Shared', 'Auth', 'LoggedUser'];

const fetchUserWithOperators = async (fortuneClient: FortuneClientInstance, userId: UserResource['id']) => {
  const {
    users: [user],
    linked: { operators, 'v-connect-accounts': vConnectAccounts },
  } = await fortuneClient.getUser<{
    operators: OperatorResource[];
    'v-connect-accounts': VConnectAccountResource[];
  }>(userId, {
    include: 'vConnectAccount,vConnectAccount.operators',
  });

  return { user, operators, vConnectAccounts };
};

const useLoggedUser = () => {
  const { isLoading, fortuneClient } = useFortuneClient();
  const userId = useLoggedUserId();

  return useQuery({
    queryKey: LoggedUserQueryKey,
    staleTime: Infinity,
    queryFn: async () => {
      assertIsDefined(fortuneClient, 'Fortune client must be defined and ready');
      assertIsDefined(userId, 'User id must be defined');
      return await fetchUserWithOperators(fortuneClient, userId);
    },
    enabled: Boolean(!isLoading && userId),
  });
};

export default useLoggedUser;
