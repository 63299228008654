import { Components, Theme } from '@mui/material/styles';

function Tabs(): Partial<Components<Theme>> {
  return {
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.contrastText,
          '&.Mui-selected': {
            color: theme.palette.primary.contrastText,
          },
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }),
        textColorInherit: ({ theme }) => ({
          opacity: 1,
          color: theme.palette.primary.contrastText,
        }),
      },
    },
  };
}

export default Tabs;
