import { BooleanParam, NumberParam, StringParam, UrlUpdateType, useQueryParam, withDefault } from 'use-query-params';
import { QueryParamConfig } from 'serialize-query-params/src/types';
import { useLastRequestsFilterSettings } from '~/views/dashboard/PendingRequestsView/api/useLastFilterSettings';
import { AirportParam, FilterAirport, NewValueType } from '~/views/dashboard/utils/filters';

export interface FilterAreaCountry {
  label: string;
  value: string;
  type: 'country' | 'area';
}

export interface PendingRequestsFilters {
  arrAirport?: FilterAirport | null;
  arrAirportDistance?: number;
  arrAirportArea?: FilterAreaCountry | null;
  deptAirport?: FilterAirport | null;
  deptAirportDistance?: number;
  deptAirportArea?: FilterAreaCountry | null;
  deptStartDate: string | null;
  deptEndDate: string | null;
  minConversionRate: number;
  minRatingHeat: number;
  preferredRequests: boolean;
  minBookingsCount: number;
  requestVid?: string;
  requestVidAndAvinode?: string;
  deletedRequests: boolean;
  unquotedRequests: boolean;
  requoteRequests: boolean;
  requestOwner: string | null;
  accountOwner: string | null;
}

const AreaCountryParam: QueryParamConfig<FilterAreaCountry | null | undefined> = {
  decode: (val) => {
    if (val === undefined) return undefined;
    val = Array.isArray(val) ? val[0] : val;
    if (!val) return null;
    const [type, value, label] = val.split('-');
    return {
      label: label || value,
      value,
      type,
    } as FilterAreaCountry;
  },
  encode: (val) => {
    const parts = val ? [val.type, val.value] : null;
    if (parts && val && val.label !== val.value) parts.push(val.label);
    return parts ? parts.join('-') : null;
  },
};

export const useFilterDeptAirport = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<FilterAirport | null>(
    'deptAirport',
    withDefault(AirportParam, lastFilterSettingQuery.data?.deptAirport || null, false),
  );
};

export const useFilterDeptAirportDistance = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<number>(
    'deptAirportDistance',
    withDefault(NumberParam, lastFilterSettingQuery.data?.deptAirportDistance || 0),
  );
};

export const useFilterDeptAirportArea = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<FilterAreaCountry | null>(
    'deptAirportArea',
    withDefault(AreaCountryParam, lastFilterSettingQuery.data?.deptAirportArea || null, false),
  );
};

export const useFilterArrivalAirport = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<FilterAirport | null>(
    'arrivalAirport',
    withDefault(AirportParam, lastFilterSettingQuery.data?.arrAirport || null, false),
  );
};

export const useFilterArrivalAirportDistance = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<number>(
    'arrivalAirportDistance',
    withDefault(NumberParam, lastFilterSettingQuery.data?.arrAirportDistance || 0),
  );
};

export const useFilterArrivalAirportArea = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<FilterAreaCountry | null>(
    'arrivalAirportArea',
    withDefault(AreaCountryParam, lastFilterSettingQuery.data?.arrAirportArea || null, false),
  );
};

export const useFilterDeptDateRange = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  let defValue = '';
  if (lastFilterSettingQuery.data?.deptStartDate && lastFilterSettingQuery.data.deptEndDate) {
    defValue = `${lastFilterSettingQuery.data.deptStartDate}/${lastFilterSettingQuery.data.deptEndDate}`;
  }
  return useQueryParam<string | null>('deptDateRange', withDefault(StringParam, defValue, false));
};

export const useFilterMinConversionRate = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<number>(
    'minConversionRate',
    withDefault(NumberParam, lastFilterSettingQuery.data?.minConversionRate || 0),
  );
};

export const useFilterMinRatingHeat = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<number>(
    'minRatingHeat',
    withDefault(NumberParam, lastFilterSettingQuery.data?.minRatingHeat || 0),
  );
};

export const useFilterPreferredRequests = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<boolean>(
    'preferredRequests',
    withDefault(BooleanParam, lastFilterSettingQuery.data?.preferredRequests || false),
  );
};

export const useFilterMinBookingsCount = (): [
  number,
  (newValue: NewValueType<number>, updateType?: UrlUpdateType) => void,
] => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  const [minBookingsCount, setMinBookingsCount] = useQueryParam<number>(
    'minBookingsCount',
    withDefault(NumberParam, lastFilterSettingQuery.data?.minBookingsCount || 0),
  );
  return [Math.min(minBookingsCount, 10), setMinBookingsCount];
};

export const useFilterRequestVid = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<string>('requestVid', withDefault(StringParam, lastFilterSettingQuery.data?.requestVid || ''));
};

export const useFilterRequestVidAndAvinode = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<string>(
    'requestVidAndAvinode',
    withDefault(StringParam, lastFilterSettingQuery.data?.requestVidAndAvinode || ''),
  );
};

export const useFilterDeletedRequests = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<boolean>(
    'deletedRequests',
    withDefault(BooleanParam, lastFilterSettingQuery.data?.deletedRequests || false),
  );
};

export const useFilterUnquotedRequests = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<boolean>(
    'unquotedRequests',
    withDefault(BooleanParam, lastFilterSettingQuery.data?.unquotedRequests || false),
  );
};

export const useFilterRequoteRequests = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<boolean>(
    'requoteRequests',
    withDefault(BooleanParam, lastFilterSettingQuery.data?.requoteRequests || false),
  );
};

export const useFilterRequestOwner = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<string | null>(
    'requestOwner',
    withDefault(StringParam, lastFilterSettingQuery.data?.requestOwner || null, false),
  );
};

export const useFilterAccountOwner = () => {
  const lastFilterSettingQuery = useLastRequestsFilterSettings();
  return useQueryParam<string | null>(
    'accountOwner',
    withDefault(StringParam, lastFilterSettingQuery.data?.accountOwner || null, false),
  );
};

export const usePendingRequestsFilters = (): PendingRequestsFilters => {
  const [preferredRequests] = useFilterPreferredRequests();
  const [deptAirport] = useFilterDeptAirport();
  const [deptAirportDistance] = useFilterDeptAirportDistance();
  const [deptAirportArea] = useFilterDeptAirportArea();
  const [arrAirportDistance] = useFilterArrivalAirportDistance();
  const [arrAirportArea] = useFilterArrivalAirportArea();
  const [arrAirport] = useFilterArrivalAirport();
  const [minBookingsCount] = useFilterMinBookingsCount();
  const [minConversionRate] = useFilterMinConversionRate();
  const [minRatingHeat] = useFilterMinRatingHeat();
  const [deptDateRange] = useFilterDeptDateRange();
  const [requestVid] = useFilterRequestVid();
  const [requestVidAndAvinode] = useFilterRequestVidAndAvinode();
  const [deletedRequests] = useFilterDeletedRequests();
  const [unquotedRequests] = useFilterUnquotedRequests();
  const [requoteRequests] = useFilterRequoteRequests();
  const [requestOwner] = useFilterRequestOwner();
  const [accountOwner] = useFilterAccountOwner();

  const [deptStartDate, deptEndDate] = deptDateRange ? deptDateRange.split('/') : [null, null];
  return {
    preferredRequests,
    deptAirport,
    deptAirportDistance,
    deptAirportArea,
    arrAirport,
    arrAirportDistance,
    arrAirportArea,
    minRatingHeat,
    minConversionRate,
    minBookingsCount,
    deptStartDate,
    deptEndDate,
    requestVid,
    requestVidAndAvinode,
    deletedRequests,
    unquotedRequests,
    requoteRequests,
    requestOwner,
    accountOwner,
  };
};
