import React from 'react';
import { Container } from '@mui/material';
import useDataTestId from '~/hooks/useDataTestId';
import QuotesList from '~/views/dashboard/QuotesView/QuotesList';
import QuoteFilterForm from '~/views/dashboard/QuotesView/QuotesFilterForm';

export const QuotesView: React.FC<{ dataTestId?: string }> = ({ dataTestId }) => {
  const dt = useDataTestId(dataTestId);

  return (
    <>
      <QuoteFilterForm />
      <Container>
        <QuotesList dataTestId={dt('list')} />
      </Container>
    </>
  );
};

export default QuotesView;
