import {
  CreateOperatorQuoteLegResource,
  CreateOperatorQuoteResource,
  FortuneClientInstance,
  FortuneResourceUpdate,
  OperatorCharterRequestResource,
  OperatorQuoteResource,
  OperatorResource,
  QuoteFuelStopChecks,
} from 'fortune-client';
import dateUtils from '~/utils/dateUtils';
import { assertIsDefined, assertIsNotEmpty, isDefined } from '~/types/typeGuards';
import {
  OperatorQuoteData,
  OperatorQuoteDataLeg,
  QuoteFuelStop,
  QuoteFuelStopReasons,
} from '~/components/forms/QuoteForm';
import { API_URL } from '~/config';

const operatorQuoteFactory = ({ fortuneClient }: { fortuneClient?: FortuneClientInstance }) => {
  const getOperatorQuoteLegs = (legs: OperatorQuoteDataLeg[], operatorId: OperatorResource['id']) => {
    return legs.map((leg, index): CreateOperatorQuoteLegResource => {
      const newArrDate = dateUtils.calculateArrival(
        leg.deptAirport.timeZone,
        leg.deptDate,
        leg.flightTime,
        leg.arrAirport.timeZone,
      );

      return {
        id: index,
        leg: index,
        duration: leg.flightTime || 0,
        deptDate: leg.deptDate,
        arrDate: newArrDate,
        passengers: leg.passengers,
        links: {
          operator: operatorId,
          deptAirport: leg.deptAirport.id,
          arrAirport: leg.arrAirport.id,
        },
      };
    });
  };

  const createQuote = async (
    values: OperatorQuoteData,
    operatorCharterRequestId: OperatorCharterRequestResource['id'],
  ) => {
    assertIsDefined(fortuneClient, 'Fortune Client is not defined.');
    const {
      aircraft,
      amount,
      currency,
      note,
      floatingFleet,
      legs,
      modifiedRouting,
      wifi,
      catering,
      fuelStop,
      fuelStopReasons,
      customsStop,
      flightAttendant,
      allowPets,
      isEmptyLeg,
    } = values;
    assertIsDefined(amount, 'amount must be defined.');
    assertIsNotEmpty(currency, 'currency cannot be empty.');

    const newLegs = getOperatorQuoteLegs(legs, values.operator);

    let fuelStopChecks: QuoteFuelStopChecks | undefined = undefined;
    if (fuelStop !== QuoteFuelStop.NO_STOP) {
      fuelStopChecks = {
        weather: fuelStopReasons.includes(QuoteFuelStopReasons.WEATHER),
        totalPayload: fuelStopReasons.includes(QuoteFuelStopReasons.TOTAL_PAYLOAD),
        dryRunway: fuelStopReasons.includes(QuoteFuelStopReasons.DRY_RUNWAY),
      };
    }

    const newQuote: CreateOperatorQuoteResource = {
      source: {
        name: 'v-connect',
      },
      note,
      status: 'accepted',
      price: {
        amount,
        currency,
      },
      floatingFleet,
      modifiedRouting,
      catering,
      wifi,
      fuelStop,
      customsStop,
      flightAttendant,
      allowPets,
      isEmptyLeg,
      fuelStopChecks,
      links: {
        aircraft,
        operator: values.operator,
        operatorQuoteLegs: newLegs.reduce<number[]>((acc, leg) => (isDefined(leg.id) ? acc.concat([leg.id]) : acc), []),
        operatorCharterRequest: operatorCharterRequestId,
      },
    };
    const {
      'operator-quotes': [opQuote],
    } = await fortuneClient.createOperatorQuote(newQuote, {
      linked: { 'operator-quote-legs': newLegs },
    });
    return opQuote;
  };

  const updateQuote = async (operatorQuoteId: OperatorQuoteResource['id'], updates: FortuneResourceUpdate[]) => {
    assertIsDefined(fortuneClient, 'Fortune Client is not defined.');
    const {
      'operator-quotes': [opQuote],
    } = await fortuneClient.updateOperatorQuote(operatorQuoteId, updates);
    return opQuote;
  };

  const uploadQuotePDF = async (operatorQuoteId: string, uploadToken: string, file: File) => {
    const data = new FormData();
    data.append('file', file);

    await fetch(`${API_URL}/operator-quotes/${operatorQuoteId}/upload-quote-pdf`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${uploadToken}`,
      },
      body: data,
    });
  };

  return {
    createQuote,
    updateQuote,
    uploadQuotePDF,
  };
};

export default operatorQuoteFactory;
