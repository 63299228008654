import { RouterProvider } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import queryClient from './clients/queryClient';
import AuthStateProvider from '~/providers/AuthStateProvider';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryFallback } from './components/errors/ErrorBoundaryFallback';
import router from './routes/routes';
import ThemeConfig from './theme/ThemeConfig';

const App: React.FC<{}> = () => (
  <ThemeConfig>
    <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <AuthStateProvider>
          <RouterProvider router={router} />
        </AuthStateProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  </ThemeConfig>
);

export default App;
