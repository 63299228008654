import React, { useEffect } from 'react';
import useLoggedUser from '~/api/useLoggedUser';
import { analyticsUtils } from '~/analytics/utils';

export const GAUserProperties: React.FC = () => {
  const { data } = useLoggedUser();
  const { user, operators } = data || {};

  useEffect(() => {
    if (user && operators) {
      analyticsUtils.setUserProperties({
        userId: user.id,
        vConnectAccountId: user.links.vConnectAccount,
        operatorIds: operators.map((el) => el.id),
      });
    }
  }, [user, operators]);

  return null;
};
