import { EVENT_PROPERTIES } from '~/analytics/types';

const _gtag: (...args: GtagProps) => void = function (...args: GtagProps) {
  const gtag = window.gtag;
  if (gtag) {
    gtag(...args);
  }
};

const setUserProperties = (properties: Record<string, unknown>) => {
  _gtag('set', 'user_properties', properties);
};

const trackEvent = <T extends keyof EVENT_PROPERTIES>(event: T, properties?: EVENT_PROPERTIES[T]) => {
  _gtag('event', event, properties);
};

export const analyticsUtils = {
  setUserProperties,
  trackEvent,
};
