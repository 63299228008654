import { useQuery } from '@tanstack/react-query';
import { FortuneClientInstance, OperatorResource } from 'fortune-client';
import useFortuneClient from '~/hooks/useFortuneClient';
import { assertIsDefined } from '~/types/typeGuards';
import useLoggedUser from '~/api/useLoggedUser';

const QuoteFormAircraftsQueryKey = {
  get: (operators?: OperatorResource['id'][]) => ['Shared', 'FindAircrafts', operators],
};

const fetchAircrafts = async (fortuneClient: FortuneClientInstance, operators?: OperatorResource['id'][]) => {
  const { aircraft } = await fortuneClient.getAircraft(
    {
      operator: { $in: operators },
    },
    { sort: 'type' },
  );
  return aircraft;
};

const useFindLoggedUserAircrafts = () => {
  const { isLoading, fortuneClient } = useFortuneClient();
  const { data } = useLoggedUser();
  const operators = (data?.operators ?? []).map((operator) => operator.id);

  return useQuery({
    queryKey: QuoteFormAircraftsQueryKey.get(operators),
    queryFn: () => {
      assertIsDefined(fortuneClient, 'Fortune client must be defined and ready');
      return fetchAircrafts(fortuneClient, operators);
    },
    enabled: Boolean(!isLoading),
  });
};

export default useFindLoggedUserAircrafts;
