import { useEffect, useState } from 'react';
import { API_URL } from '../config';
import _isObject from 'lodash/isObject';
import { getPersistedValue, PersistedStateKeys } from '~/hooks/usePersistedState';
import fortuneClientFactory, { FortuneClientInstance, FortuneConnectionOption } from 'fortune-client';
import { isLoginSuccess, loginAsApplication, loginByRefreshToken } from '~/utils/authentication';
import { AuthStateContextProps, useAuthState } from '~/providers/AuthStateProvider';
import { getVisitorId } from '~/utils/fingerprint';

let instance: FortuneClientInstance | null = null;
let isReady = false;

const instanceReady = async (instance: FortuneClientInstance): Promise<FortuneClientInstance> => {
  await instance.ready;
  if (!isReady) {
    isReady = true;
    const visitorId = await getVisitorId();
    if (visitorId) {
      instance.changeHeader('x-device-id', visitorId);
    }
    instance.extendRequestQueryKeys(['deptFuzzyFilter', 'arrFuzzyFilter']);
  }
  return instance;
};

export const createFortuneClient = async (
  config: { apiUrl: string },
  authState: AuthStateContextProps,
): Promise<FortuneClientInstance> => {
  if (instance) {
    return instanceReady(instance);
  }

  const appToken = await loginAsApplication();

  const connectionOptions: FortuneConnectionOption = {
    url: config.apiUrl,
    headers: {
      Authorization: `${appToken.tokenType} ${appToken.accessToken}`,
    },
  };

  instance = fortuneClientFactory([connectionOptions]);
  instance.onErrorHook(makeOnRequestErrorHook(instance, authState));
  return instanceReady(instance);
};

export const makeOnRequestErrorHook =
  (fortuneClient: FortuneClientInstance, authState: AuthStateContextProps) =>
  async (err: unknown, dispatch: () => unknown, continueWithError: () => void) => {
    if (err && _isObject(err) && 'statusCode' in err && err.statusCode === 403) {
      const refreshToken = getPersistedValue(PersistedStateKeys.VConnectRefreshToken, null) as string | null;
      const userId = getPersistedValue(PersistedStateKeys.VConnectUserId, null) as string | null;
      if (refreshToken && userId) {
        const newToken = await loginByRefreshToken(refreshToken);
        if (isLoginSuccess(newToken)) {
          fortuneClient.changeHeader('Authorization', `Bearer ${newToken.accessToken}`);
          authState.setAuthState({ token: newToken.accessToken, refreshToken: newToken.refreshToken, userId: userId });
          return dispatch();
        }
      }
      authState.clearAuthState();
    }
    continueWithError();
  };

const useFortuneClient = () => {
  const [fortuneClient, setFortuneClient] = useState<FortuneClientInstance>();
  const authState = useAuthState();

  useEffect(() => {
    async function prepareFortuneClient() {
      if (!fortuneClient) {
        const fc = await createFortuneClient(
          {
            apiUrl: API_URL,
          },
          authState,
        );

        const currentToken = getPersistedValue(PersistedStateKeys.VConnectToken, null) as string | null;
        if (currentToken) fc.changeHeader('Authorization', `Bearer ${currentToken}`);

        setFortuneClient(fc);
      }
    }
    prepareFortuneClient();
  }, [fortuneClient, setFortuneClient, authState]);

  return { fortuneClient, isLoading: !fortuneClient };
};

export default useFortuneClient;
