import React from 'react';
import {
  Alert,
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import { SortDirection } from '@mui/material/TableCell/TableCell';
import { SortingDirection, TableHeaderSortItem } from '~/utils/sorting';
import { RouteConfig } from '~/routes/routeConfig';
import PageEndMarker from '~/components/list/PageEndMarker';
import useDataTestId from '~/hooks/useDataTestId';
import useOperatorQuotes, { OperatorQuote } from '~/views/dashboard/QuotesView/api/useOperatorQuotes';
import { OperatorQuotesSortingMap, useOperatorQuotesSorting } from '~/views/dashboard/QuotesView/hooks/sorting';
import { styled } from '@mui/material/styles';
import LegsRoute from '~/views/dashboard/components/LegsRoute';
import dateUtils from '~/utils/dateUtils';
import RequestVID from '~/views/dashboard/components/RequestVID';
import QuoteStatus from '~/views/dashboard/components/QuoteStatus';
import { OpenInNew } from '@mui/icons-material';
import QuoteActions from '~/views/dashboard/components/QuoteActions';
import { resolveErrorMessage } from '~/views/dashboard/utils/errorResolver';
import useScrollTopKeeperNavigation from '~/hooks/useScrollTopKeeperNavigation';
import useFiltersTracking from '~/hooks/useFiltersTracking';

const StyledTableCell = styled(TableCell)(() => ({
  borderBottom: 'none',
  whiteSpace: 'nowrap',
}));

const TableHeaderItems: TableHeaderSortItem[] = [
  {
    label: 'Quote Status',
  },
  {
    label: 'Aircraft / Type',
  },
  {
    label: 'Route',
  },
  {
    label: 'Price',
    sort: OperatorQuotesSortingMap.price,
  },
  {
    label: 'Created At',
    sort: OperatorQuotesSortingMap.createdAt,
  },
  {
    label: 'Accepted/Rejected At',
  },
  {
    label: 'Actions',
  },
];

export const QuotesList: React.FC<{ dataTestId?: string }> = ({ dataTestId }) => {
  const dt = useDataTestId(dataTestId);
  const { navigateTo } = useScrollTopKeeperNavigation();

  const opQuotesQuery = useOperatorQuotes();
  const { isPending, isFetching, isFetchingNextPage, error, data } = opQuotesQuery;
  const opQuotes = data?.pages.flatMap((el) => el.opQuotes) || [];
  const isDataLoading = (isPending || isFetching) && !isFetchingNextPage;
  useFiltersTracking(opQuotes.length, isDataLoading, 'quotes');

  const { sorting, getOnSortHandler } = useOperatorQuotesSorting();

  const onClick = (opQuote: OperatorQuote) => {
    if (!opQuote.requestOpened || !opQuote.additionalDetails?.charterRequestVid) return;
    navigateTo(RouteConfig.RequestDetail.buildLink({ vid: opQuote.additionalDetails.charterRequestVid }));
  };

  return (
    <Box data-testid={dt()}>
      {!isDataLoading && error ? <Alert severity="error">{resolveErrorMessage(error)}</Alert> : null}
      {!error && (
        <>
          <TableContainer sx={{ overflow: 'visible' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {TableHeaderItems.map((item, index) => {
                    const hasSorting = !!item.sort;
                    return (
                      <StyledTableCell
                        key={index}
                        align={index !== TableHeaderItems.length - 1 ? 'left' : 'right'}
                        sortDirection={
                          hasSorting && sorting.sort === item.sort?.key ? (sorting.direction as SortDirection) : false
                        }
                      >
                        {hasSorting ? (
                          <TableSortLabel
                            sx={{ fontWeight: 'bold' }}
                            active={sorting.sort === item.sort?.key}
                            direction={
                              sorting.sort === item.sort?.key
                                ? (sorting.direction as SortingDirection)
                                : item.sort?.defaultDirection
                            }
                            onClick={getOnSortHandler(item)}
                          >
                            {item.label}
                          </TableSortLabel>
                        ) : (
                          item.label
                        )}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {opQuotes.length ? (
                  opQuotes.map((opQuote) => {
                    const price = opQuote.price.source || opQuote.price;
                    return (
                      <TableRow
                        key={`quote_section_${opQuote.id}`}
                        hover
                        sx={{ cursor: opQuote.requestOpened ? 'pointer' : 'default' }}
                        onClick={() => onClick(opQuote)}
                      >
                        <TableCell sx={{ textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
                          <QuoteStatus status={opQuote.vConnectStatus} requote={opQuote.requotePending} />
                          <RequestVID vid={opQuote.additionalDetails?.charterRequestVid} />
                        </TableCell>
                        <TableCell align="left">
                          {opQuote.links.aircraft
                            ? `${opQuote.links.aircraft.registration} / ${opQuote.links.aircraft.type}`
                            : 'No aircraft available'}
                        </TableCell>
                        <TableCell>
                          <LegsRoute legs={opQuote.links.operatorQuoteLegs} maxAirports={4} displayDate={true} />
                        </TableCell>
                        <TableCell>{`${price.locale.amount} ${price.currency}`}</TableCell>
                        <TableCell>{dateUtils.toMoment(opQuote.createdAt).format('YYYY-MMM-DD HH:mm')}</TableCell>
                        <TableCell>
                          {opQuote.quoteAcceptedAt && (
                            <Typography
                              variant="body2"
                              sx={{ textDecoration: opQuote.quoteRejectedAt ? 'line-through' : null }}
                            >
                              {dateUtils.toMoment(opQuote.quoteAcceptedAt).format('YYYY-MMM-DD HH:mm')}
                            </Typography>
                          )}
                          {opQuote.quoteRejectedAt && (
                            <Typography variant="body2" color="error.main">
                              {dateUtils.toMoment(opQuote.quoteRejectedAt).format('YYYY-MMM-DD HH:mm')}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
                          {!!opQuote.acceptedInfo && (
                            <>
                              {opQuote.acceptedInfo.aircraftType}
                              <br />
                              <Typography
                                variant="body2"
                                color="primary.main"
                              >{`${opQuote.acceptedInfo.price.locale.amount} ${opQuote.acceptedInfo.price.currency}`}</Typography>
                            </>
                          )}
                          {!opQuote.acceptedInfo && (
                            <>
                              <QuoteActions
                                requestVid={opQuote.additionalDetails?.charterRequestVid}
                                quoteId={opQuote.id}
                                quotePrice={opQuote.price}
                                availableActions={opQuote.requestOpened ? opQuote.quoteAvailableActions : []}
                                aircraftType={opQuote.links.aircraft?.type}
                                aircraftTail={opQuote.links.aircraft?.registration}
                                tooltip={opQuote.requestOpened ? undefined : 'Request is no longer available'}
                                cancelLocation={RouteConfig.Quotes.buildLink()}
                                dataTestId={'quote-actions'}
                              />
                              {!!opQuote.additionalDetails?.charterRequestVid && (
                                <Tooltip
                                  title={opQuote.requestOpened ? 'Open in new tab' : 'Request is no longer available'}
                                  enterDelay={200}
                                  placement="bottom"
                                >
                                  <span>
                                    <IconButton
                                      href={RouteConfig.RequestDetail.buildLink({
                                        vid: opQuote.additionalDetails.charterRequestVid,
                                      })}
                                      target="_blank"
                                      onClick={(e) => e.stopPropagation()}
                                      disabled={!opQuote.requestOpened}
                                    >
                                      <OpenInNew fontSize="small" />
                                    </IconButton>
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={TableHeaderItems.length}>Quotes not found</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <StyledTableCell colSpan={TableHeaderItems.length}>
                    <PageEndMarker height={150} query={opQuotesQuery} />
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Box>
  );
};

export default QuotesList;
