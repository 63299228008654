import React from 'react';
import _isNil from 'lodash/isNil';
import _isEmpty from 'lodash/isNil';
import { Navigate } from 'react-router-dom';
import useLoggedUser from '../../api/useLoggedUser';
import { useLoggedUserId } from '../../providers/AuthStateProvider';
import { RouteConfig } from '../routeConfig';
import LoadingScreen from '~/components/loaders/LoadingScreen';
import { OperatorResource } from 'fortune-client';

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const userId = useLoggedUserId();
  const { data, isPending } = useLoggedUser();
  const { user, operators } = data || {};

  if (!userId) return <Navigate to={RouteConfig.Login.buildLink()} />;
  if (isPending) return <LoadingScreen />;

  //API can send back "external" as plain string when cross-service request cannot be satisfied
  const ops = operators as OperatorResource[] | string | undefined;
  if (_isNil(user) || _isNil(ops) || _isEmpty(ops) || ops === 'external')
    return <Navigate to={RouteConfig.NoAccess.buildLink()} />;

  return <>{children}</>;
};

export default AuthGuard;
