import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import { useLastOperatorQuotesFilterSettings } from '~/views/dashboard/PendingRequestsView/api/useLastFilterSettings';
import { AirportParam, FilterAirport } from '~/views/dashboard/utils/filters';

export interface OperatorQuotesFilters {
  createdStartDate: string | null;
  createdEndDate: string | null;
  legStartDeptDate: string | null;
  legEndDeptDate: string | null;
  aircraft: string | null;
  aircraftType: string | null;
  legDeptAirport?: FilterAirport | null;
  legArrAirport?: FilterAirport | null;
  requestVid?: string;
  status: string | null;
}

export const useFilterCreatedDateRange = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  let defValue = '';
  if (lastFilterSettingQuery.data?.createdStartDate && lastFilterSettingQuery.data.createdEndDate) {
    defValue = `${lastFilterSettingQuery.data.createdStartDate}/${lastFilterSettingQuery.data.createdEndDate}`;
  }
  return useQueryParam<string | null>('createdDateRange', withDefault(StringParam, defValue, false));
};

export const useFilterLegDeptDateRange = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  let defValue = '';
  if (lastFilterSettingQuery.data?.legStartDeptDate && lastFilterSettingQuery.data.legEndDeptDate) {
    defValue = `${lastFilterSettingQuery.data.legStartDeptDate}/${lastFilterSettingQuery.data.legEndDeptDate}`;
  }
  return useQueryParam<string | null>('legDeptDateRange', withDefault(StringParam, defValue, false));
};

export const useFilterAircraft = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<string | null>(
    'aircraft',
    withDefault(StringParam, lastFilterSettingQuery.data?.aircraft || null, false),
  );
};

export const useFilterAircraftType = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<string | null>(
    'aircraftType',
    withDefault(StringParam, lastFilterSettingQuery.data?.aircraftType || null, false),
  );
};

export const useFilterLegDeptAirport = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<FilterAirport | null>(
    'legDeptAirport',
    withDefault(AirportParam, lastFilterSettingQuery.data?.legDeptAirport || null, false),
  );
};

export const useFilterLegArrivalAirport = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<FilterAirport | null>(
    'legArrAirport',
    withDefault(AirportParam, lastFilterSettingQuery.data?.legArrAirport || null, false),
  );
};

export const useFilterVid = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<string>('requestVid', withDefault(StringParam, lastFilterSettingQuery.data?.requestVid || ''));
};

export const useFilterStatus = () => {
  const lastFilterSettingQuery = useLastOperatorQuotesFilterSettings();
  return useQueryParam<string | null>(
    'status',
    withDefault(StringParam, lastFilterSettingQuery.data?.status || null, false),
  );
};

export const useOperatorQuotesFilters = (): OperatorQuotesFilters => {
  const [createdDateRange] = useFilterCreatedDateRange();
  const [legDeptDateRange] = useFilterLegDeptDateRange();
  const [aircraft] = useFilterAircraft();
  const [aircraftType] = useFilterAircraftType();
  const [legDeptAirport] = useFilterLegDeptAirport();
  const [legArrAirport] = useFilterLegArrivalAirport();
  const [requestVid] = useFilterVid();
  const [status] = useFilterStatus();

  const [createdStartDate, createdEndDate] = createdDateRange ? createdDateRange.split('/') : [null, null];
  const [legStartDeptDate, legEndDeptDate] = legDeptDateRange ? legDeptDateRange.split('/') : [null, null];
  return {
    createdStartDate,
    createdEndDate,
    legStartDeptDate,
    legEndDeptDate,
    aircraft,
    aircraftType,
    legDeptAirport,
    legArrAirport,
    requestVid,
    status,
  };
};
