import React, { ChangeEvent, useRef, useState } from 'react';
import * as PDFJS from 'pdfjs-dist';
import { AirXParser } from './parsers/airx';
import { GlockAviationParser } from './parsers/glockAviation';
import useFortuneClient from '~/hooks/useFortuneClient';
import { CircularProgress } from '@mui/material';
import { ParsedQuote } from '~/components/forms/QuoteForm/ocr/parsers/types';
import { OperatorResource } from 'fortune-client';
import { SkysideParser } from '~/components/forms/QuoteForm/ocr/parsers/skyside';
import { OcrParsingError } from './parsers/base';
import useDataTestId from '~/hooks/useDataTestId';
import { VistaJetParser } from '~/components/forms/QuoteForm/ocr/parsers/vistajet';

PDFJS.GlobalWorkerOptions.workerSrc = window.location.origin + '/js/pdf.worker.min.mjs';

export interface OCRProps {
  fileUploadRef: React.Ref<HTMLInputElement>;
  onComplete: (quote: ParsedQuote, ocrErrors: OcrParsingError[]) => void;
  onError: (error: unknown) => void;
  operators: OperatorResource[];
  dataTestId?: string;
}

const Parsers = {
  'Glock Aviation': GlockAviationParser,
  Skyside: SkysideParser,
  VistaJet: VistaJetParser,
  __default: AirXParser,
};

type ParserKey = keyof typeof Parsers;
type Parser = (typeof Parsers)[ParserKey];
const getParser = (operators: OperatorResource[]): Parser => {
  return operators.reduce<Parser>((parser, operator) => {
    Object.keys(Parsers).forEach((key) => {
      if (operator.name.includes(key)) {
        parser = Parsers[key as ParserKey];
      }
    });
    return parser;
  }, Parsers.__default);
};

const OCR: React.FC<OCRProps> = ({ fileUploadRef, operators, onComplete, onError, dataTestId }) => {
  const dt = useDataTestId(dataTestId);
  const { fortuneClient } = useFortuneClient();
  const [debug] = useState(false);
  const [ocr, setOcr] = useState('');
  const [loading, setLoading] = useState(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const rectangleCanvasRef = useRef<HTMLCanvasElement>(null);
  const operatorIds = operators.map((operator) => operator.id);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && canvasRef.current && rectangleCanvasRef.current && fortuneClient) {
      try {
        setLoading(true);
        const Parser = getParser(operators);
        const parser = new Parser(
          e.target.files[0],
          canvasRef.current,
          rectangleCanvasRef.current,
          fortuneClient,
          operatorIds,
        );
        await parser.init();

        const result = await parser.getParsedQuote();
        const { success, data: quote } = result;
        if (debug) {
          setOcr(JSON.stringify(quote, undefined, 2));
        }
        onComplete(quote, success ? [] : result.errors);
      } catch (err) {
        onError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <div>
        <>
          Upload PDF: <input type="file" ref={fileUploadRef} onChange={handleFileChange} data-testid={dt('file')} />
        </>
        {loading ? <CircularProgress data-testid={dt('loader')} /> : debug ? <pre>{ocr}</pre> : null}
      </div>
      <div style={{ position: 'relative', display: debug ? 'block' : 'none' }}>
        <canvas ref={canvasRef} />
        <canvas ref={rectangleCanvasRef} style={{ position: 'absolute', left: 0, top: 0 }} />
      </div>
      <div id="virtual-canvases" style={{ display: debug ? 'block' : 'none' }}></div>
    </div>
  );
};

export default OCR;
