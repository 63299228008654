import { createWorker, OEM, Rectangle, Worker } from 'tesseract.js';
import * as PDFJS from 'pdfjs-dist';
import _mapValues from 'lodash/mapValues';
import { FortuneClientInstance } from 'fortune-client';
import { TextContent } from 'pdfjs-dist/types/web/text_layer_builder';
import { TextItem } from 'pdfjs-dist/types/src/display/api';
import { ParsedQuote, ParsedQuoteTextItem } from './types';
import { isError } from '~/types/typeGuards';
import moment from 'moment-timezone';

type OcrParsingErrorContext = 'aircraft' | 'price' | 'legs' | 'allowed-pets' | 'fuel-stop' | 'notes' | 'wifi';

export class OcrParsingError extends Error {
  context: OcrParsingErrorContext;
  parentError: unknown;

  constructor(context: OcrParsingErrorContext, error: unknown) {
    super(isError(error) ? error.message : 'Something went wrong'); // (1)
    this.name = 'OcrParsingError'; // (2)
    this.stack = isError(error) ? error.stack : new Error(this.message).stack;
    this.cause = isError(error) ? error.cause : undefined;
    this.context = context;
    this.parentError = error;
  }

  getContextHumanName() {
    switch (this.context) {
      case 'allowed-pets':
        return 'allowed pets';
      case 'fuel-stop':
        return 'fuel stop';
      default:
        return this.context;
    }
  }
}

type DataGetter<T> = Promise<T> | T;

export abstract class BaseParser {
  file: File;
  scale = 1;

  fortuneClient: FortuneClientInstance;
  operatorIds: string[];

  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D | null = null;
  rectangleCanvas: HTMLCanvasElement;
  rectangleContext: CanvasRenderingContext2D | null = null;
  workers: Worker[] = [];
  allWorkers: Worker[] = [];
  textContent: TextContent | null = null;

  constructor(
    file: File,
    canvas: HTMLCanvasElement,
    rectangleCanvas: HTMLCanvasElement,
    fortuneClient: FortuneClientInstance,
    operatorIds: string[],
  ) {
    this.operatorIds = operatorIds;
    this.file = file;
    this.canvas = canvas;
    this.rectangleCanvas = rectangleCanvas;
    this.fortuneClient = fortuneClient;
    this.context = this.canvas.getContext('2d');
    this.rectangleContext = this.rectangleCanvas.getContext('2d');

    this.context?.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.rectangleContext?.clearRect(0, 0, this.rectangleCanvas.width, this.rectangleCanvas.height);
  }

  async init() {
    const data = URL.createObjectURL(this.file);
    const [pdf, ...workers] = await Promise.all([
      PDFJS.getDocument(data).promise,
      ...Array.from(Array(3)).map(() => createWorker('eng', OEM.LSTM_ONLY)),
    ]);
    this.allWorkers = workers;
    this.workers = [...workers];

    const page = await pdf.getPage(1);
    this.textContent = await page.getTextContent();

    const viewport = page.getViewport({ scale: this.scale });
    this.canvas.width = this.rectangleCanvas.width = viewport.width;
    this.canvas.height = this.rectangleCanvas.height = viewport.height;

    if (this.context && this.rectangleContext) {
      await page.render({
        canvasContext: this.context,
        viewport: viewport,
      }).promise;
    }
  }

  async finish() {
    await Promise.all(this.allWorkers.map((worker) => worker.terminate()));
  }

  assertInitialization(): asserts this is {
    worker: Worker;
    context: CanvasRenderingContext2D;
    rectangleContext: CanvasRenderingContext2D;
    textContent: TextContent;
  } {
    if (!this.context || !this.rectangleContext || !this.textContent) throw new Error('Not initialized');
  }

  getRectangle(rectangle: Rectangle): Rectangle {
    return _mapValues(rectangle, (value) => value * this.scale);
  }

  async withWorker(callback: (worker: Worker) => Promise<string>): Promise<string> {
    const worker = this.workers.pop();
    if (worker) {
      const result = await callback(worker);
      this.workers.push(worker);
      return result;
    }
    // waiting for free worker
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, 50);
    });
    return this.withWorker(callback);
  }

  async getTextFromRectangle(rectangle: Rectangle): Promise<string> {
    this.assertInitialization();
    const scaledRectangle = this.getRectangle(rectangle);
    this.rectangleContext.rect(
      scaledRectangle.left,
      scaledRectangle.top,
      scaledRectangle.width,
      scaledRectangle.height,
    );
    this.rectangleContext.lineWidth = 1;
    this.rectangleContext.strokeStyle = 'red';
    this.rectangleContext.stroke();

    return await this.withWorker(async (worker) => {
      const virtualCanvas = document.createElement('canvas');

      virtualCanvas.width = scaledRectangle.width * 2;
      virtualCanvas.height = scaledRectangle.height * 2;

      const ctx = virtualCanvas.getContext('2d');

      ctx?.drawImage(
        this.canvas,
        scaledRectangle.left,
        scaledRectangle.top,
        scaledRectangle.width,
        scaledRectangle.height,
        0,
        0,
        scaledRectangle.width * 2,
        scaledRectangle.height * 2,
      );

      document.getElementById('virtual-canvases')?.appendChild(virtualCanvas);

      const {
        data: { text },
      } = await worker.recognize(virtualCanvas);

      return text.trim();
    });
  }

  textItemToParsedQuoteTextItem(item: TextItem, index: number): ParsedQuoteTextItem {
    return {
      left: item.transform[4],
      top: this.canvas.height / this.scale - item.transform[5] - item.height, // Looks like 5 elem is position from bottom, need to subtract it from height
      width: item.width,
      height: item.height,
      index,
      transform: item.transform,
      str: item.str,
    };
  }

  getTextItem(
    text: string,
    startPosition = 0,
    comparation: 'strict' | 'includes' = 'strict',
  ): ParsedQuoteTextItem | null {
    try {
      this.assertInitialization();
      const index = (this.textContent.items as TextItem[]).findIndex((item, index) => {
        let isEqual = false;
        switch (comparation) {
          case 'strict':
            isEqual = item.str === text;
            break;
          case 'includes':
            isEqual = item.str.includes(text);
            break;
        }
        return isEqual && index >= startPosition;
      });
      if (index === -1) return null;
      return this.textItemToParsedQuoteTextItem(this.textContent.items[index] as TextItem, index);
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  getTextItemOnSameLine(textItem: ParsedQuoteTextItem) {
    this.assertInitialization();
    return (this.textContent.items as TextItem[]).reduce<ParsedQuoteTextItem[]>((memo, item, index) => {
      if (textItem.index !== index && textItem.transform[5] === item.transform[5] && !!item.str.trim()) {
        memo.push(this.textItemToParsedQuoteTextItem(item, index));
      }
      return memo;
    }, []);
  }

  getTextItemOnSameColumn(textItem: ParsedQuoteTextItem, endTextItem?: ParsedQuoteTextItem | null) {
    this.assertInitialization();
    return (this.textContent.items as TextItem[]).reduce<ParsedQuoteTextItem[]>((memo, item, index) => {
      if (
        textItem.index !== index &&
        (!endTextItem || textItem.index < endTextItem.index) &&
        textItem.transform[4] === item.transform[4] &&
        !!item.str.trim()
      ) {
        memo.push(this.textItemToParsedQuoteTextItem(item, index));
      }
      return memo;
    }, []);
  }

  async getAircraftResult(aircraft: string | null, aircraftType: string | null) {
    const empty = { aircraft: null, aircraftType: null, floatingFleet: true };
    try {
      if (!aircraft && aircraftType) {
        const firstOfType = await this.fortuneClient.getAircraft(
          {
            type: aircraftType,
            operator: { $in: this.operatorIds },
          },
          { fields: 'id,operator', limit: 1 },
        );
        if (firstOfType.aircraft.length) {
          return {
            operator: firstOfType.aircraft[0].links.operator,
            aircraft: firstOfType.aircraft[0].id,
            floatingFleet: true,
            aircraftType,
          };
        } else {
          return empty;
        }
      }

      if (!aircraft) return empty;

      const aircraftResponse = await this.fortuneClient.getAircraft(aircraft, { fields: 'operator' });
      const operatorId = aircraftResponse.aircraft[0]?.links.operator || null;

      if (!operatorId) return empty;

      return {
        operator: operatorId,
        aircraft,
        floatingFleet: false,
        aircraftType,
      };
    } catch (err) {
      console.log('getAircraftResult err', err);
    }
    return empty;
  }

  async getAirportsResult(deptAirportIcao: string | undefined, arrAirportIcao: string | undefined) {
    const { airports } = await this.fortuneClient.getAirports(
      {
        icao: { $in: [deptAirportIcao, arrAirportIcao] },
      },
      {
        fields: 'id,airportCode,displayInfo,timeZone,icao',
      },
    );

    return {
      deptAirport: airports.find((airport) => airport.icao === deptAirportIcao),
      arrAirport: airports.find((airport) => airport.icao === arrAirportIcao),
    };
  }

  getLegDeptDate(date: moment.Moment, time: string, timeZone: string | undefined) {
    return {
      date: date.format('YYYY-MM-DD'),
      time,
      timeZone: date.tz(timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone).format('Z'),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async safe<T>(context: OcrParsingErrorContext, getter: () => DataGetter<T>): Promise<OcrParsingError | Awaited<T>> {
    try {
      return await getter();
    } catch (e) {
      return new OcrParsingError(context, e);
    }
  }

  async getParsedQuote(): Promise<
    { success: true; data: ParsedQuote } | { success: false; data: ParsedQuote; errors: OcrParsingError[] }
  > {
    const [aircraft, price, legs, allowPets, fuelStop, note, wifi] = await Promise.all([
      this.safe('aircraft', this.getAircraft.bind(this)),
      this.safe('price', this.getPrice.bind(this)),
      this.safe('legs', this.getLegs.bind(this)),
      this.safe('allowed-pets', this.getAllowPets.bind(this)),
      this.safe('fuel-stop', this.getFuelStop.bind(this)),
      this.safe('notes', this.getNotes.bind(this)),
      this.safe('wifi', this.getWifi.bind(this)),
    ]);
    await this.finish();

    const ensureNonError = <T extends {}>(obj: T | OcrParsingError | null): T | null => (isError(obj) ? null : obj);

    const data: ParsedQuote = {
      operator: null,
      aircraft: null,
      aircraftType: null,
      floatingFleet: null,
      ...ensureNonError(aircraft),
      price: ensureNonError(price),
      legs: ensureNonError(legs) ?? [],
      allowPets: ensureNonError(allowPets),
      fuelStop: ensureNonError(fuelStop),
      note: ensureNonError(note),
      wifi: ensureNonError(wifi),
    };

    const errors: OcrParsingError[] = [aircraft, price, legs, allowPets, fuelStop, note].reduce((acc, el) => {
      if (el instanceof OcrParsingError) acc.push(el);
      return acc;
    }, [] as OcrParsingError[]);

    if (errors.length) {
      return {
        success: false,
        errors,
        data,
      };
    }
    return { success: true, data };
  }

  abstract getAircraft(): Promise<
    Partial<Pick<ParsedQuote, 'operator' | 'aircraft' | 'aircraftType' | 'floatingFleet'>>
  >;
  abstract getPrice(): DataGetter<ParsedQuote['price']>;
  abstract getLegs(): DataGetter<ParsedQuote['legs'] | null>;
  abstract getAllowPets(): DataGetter<ParsedQuote['allowPets']>;
  abstract getFuelStop(): DataGetter<ParsedQuote['fuelStop']>;
  abstract getNotes(): DataGetter<ParsedQuote['note']>;
  abstract getWifi(): DataGetter<ParsedQuote['wifi']>;
}
