import { ReactNode } from 'react';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { GlobalStyles } from '@mui/material';
import { globalStyles } from './globalStyles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import ComponentsOverrides from './overrides';
import { enGB } from 'date-fns/locale';
import VConnectPalette from './palette';
import VConnectTypography from '~/theme/typography';

interface ThemeConfigProps {
  children: ReactNode;
}

function ThemeConfig({ children }: ThemeConfigProps) {
  const theme = createTheme({
    palette: VConnectPalette,
    typography: VConnectTypography,
    components: ComponentsOverrides(),
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
          <CssBaseline />
          <GlobalStyles styles={(theme) => globalStyles(theme)} />
          {children}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;
