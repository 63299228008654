import { Palette } from '@mui/material';

export const THEME_COLORS = {
  green: '#74AF2B',
  red: '#EF5350',
  amber: '#ffca28',
  lightBlue: '#29b6f6',
  yellow: '#ffee58',
  grey: '#BDBDBD',
  orange: '#ff7043',
  black: '#27272A',
};

const VConnectPalette: Omit<Partial<Palette>, 'action'> & {
  action: Partial<Palette['action']>;
} = {
  primary: {
    main: THEME_COLORS.green,
    dark: THEME_COLORS.green,
    light: THEME_COLORS.green,
    contrastText: '#FFFFFF',
  },
  secondary: {
    main: THEME_COLORS.black,
    dark: THEME_COLORS.black,
    light: THEME_COLORS.black,
    contrastText: '#FFFFFF',
  },
  error: {
    main: '#D32F2F',
    dark: '#C62828',
    light: THEME_COLORS.red,
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#ED6C02',
    dark: '#E65100',
    light: '#FF9800',
    contrastText: '#FFFFFF',
  },
  info: {
    main: '#0288D1',
    dark: '#01579B',
    light: '#03A9F4',
    contrastText: '#FFFFFF',
  },
  success: {
    main: '#2E7D32',
    dark: '#1B5E20',
    light: '#4CAF50',
    contrastText: '#FFFFFF',
  },
  action: {
    selectedOpacity: 0.4,
  },
};

export default VConnectPalette;
