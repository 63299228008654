import { Theme } from '@emotion/react';
import { Components } from '@mui/material';

function Slider(): Partial<Components<Theme>> {
  return {
    MuiSlider: {
      defaultProps: {
        color: 'secondary',
      },
    },
  };
}

export default Slider;
