import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from '@mui/material';
import useDataTestId from '../../hooks/useDataTestId';
import { ModalProps } from './Modal';

interface ConfirmDialogProps extends ModalProps {
  closeModal: (props?: { action: 'CONFIRM' } | { action: 'CLOSE' }) => void;
  title: string;
  text: string;
  dataTestId?: string;
}

const ConfirmDialog = ({ title, text, closeModal, dataTestId }: ConfirmDialogProps) => {
  const dt = useDataTestId(dataTestId);
  const theme = useTheme();

  return (
    <Dialog data-testid={dt()} maxWidth="sm" open onClose={() => closeModal()} fullWidth>
      <DialogTitle data-testid={dt('title')}>{title}</DialogTitle>
      <DialogContent data-testid={dt('content')}>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{ marginLeft: theme.spacing(2) }}
          data-testid={dt('cancelButton')}
          onClick={() => closeModal()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: theme.spacing(2) }}
          data-testid={dt('confirmButton')}
          onClick={() => closeModal({ action: 'CONFIRM' })}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmDialog;
