import React from 'react';
import { useFilterAircraft, useFilterAircraftType } from '~/views/dashboard/QuotesView/hooks/filters';
import AircraftTypeAutocomplete from '~/components/forms/AircraftTypeAutocomplete';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';

export const AircraftTypeFilter = () => {
  const [aircraftType, setAircraftType] = useFilterAircraftType();
  const [, setAircraft] = useFilterAircraft();

  return (
    <AircraftTypeAutocomplete
      onTypeChanged={(type) => {
        setAircraft(null);
        setAircraftType(type);
        analyticsUtils.trackEvent(EVENT_NAMES.QUOTES_FILTER_CHANGED, { name: 'aircraft_type', value: type });
      }}
      value={aircraftType}
    />
  );
};
