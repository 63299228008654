import { Box, Toolbar } from '@mui/material';
import { Outlet } from 'react-router-dom';
import DashboardHeader from './DashboardHeader';
import { ErrorBoundary } from 'react-error-boundary';
import { Suspense } from 'react';
import LoadingScreen from '~/components/loaders/LoadingScreen';
import { ErrorBoundaryFallback } from '~/components/errors/ErrorBoundaryFallback';
import { GAUserProperties } from '~/analytics/GAUserProperties';
import useDataTestId from '~/hooks/useDataTestId';

const DashboardLayout = ({ dataTestId }: { dataTestId?: string }) => {
  const dt = useDataTestId(dataTestId);
  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <GAUserProperties />
      <DashboardHeader dataTestId={dt('header')} />
      <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', overflow: 'auto' }}>
        <Toolbar />
        <Suspense fallback={<LoadingScreen />}>
          <ErrorBoundary fallbackRender={ErrorBoundaryFallback}>
            <Outlet />
          </ErrorBoundary>
        </Suspense>
      </Box>
    </Box>
  );
};

export default DashboardLayout;
