import AircraftAutocomplete from '~/components/forms/AircraftAutocomplete';
import React from 'react';
import { useFilterAircraft, useFilterAircraftType } from '~/views/dashboard/QuotesView/hooks/filters';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';

export const AircraftFilter = () => {
  const [aircraft, setAircraft] = useFilterAircraft();
  const [, setAircraftType] = useFilterAircraftType();
  return (
    <AircraftAutocomplete
      onAircraftChanged={(aircraft) => {
        setAircraftType(null);
        setAircraft(aircraft?.id || null);
        analyticsUtils.trackEvent(EVENT_NAMES.QUOTES_FILTER_CHANGED, { name: 'aircraft', value: aircraft?.id || null });
      }}
      value={aircraft}
    />
  );
};
