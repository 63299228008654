export enum EVENT_NAMES {
  FILTER_CHANGED = 'filter_changed',
  QUOTES_FILTER_CHANGED = 'quotes_filter_changed',
  REQUEST_MARK_AS_PREFERRED = 'request_mark_as_preferred',
  REQUEST_MARK_AS_DELETED = 'request_mark_as_deleted',
  QUOTE_FORM_INTERACTIONS = 'quote_form_interaction',
  QUOTE_FORM_NEW_LEG = 'quote_form_new_leg',
  QUOTE_FORM_EDIT_LEG = 'quote_form_edit_leg',
  QUOTE_FORM_SAVE_LEG = 'quote_form_save_leg',
  QUOTE_FORM_DELETE_LEG = 'quote_form_delete_leg',
  QUOTE_SUBMITTED = 'quote_submitted',
  QUOTE_MARK_AS_UNAVAILABLE = 'quote_mark_as_unavailable',
}

export interface EVENT_PROPERTIES {
  [EVENT_NAMES.FILTER_CHANGED]: {
    name:
      | 'dept_airport'
      | 'arr_airport'
      | 'dept_airport_distance'
      | 'dept_airport_area'
      | 'arr_airport_distance'
      | 'arr_airport_area'
      | 'dept_date_range'
      | 'min_bookings'
      | 'min_conversion_rate'
      | 'min_rating_heat'
      | 'only_preferred'
      | 'only_deleted'
      | 'only_unquoted'
      | 'only_requote'
      | 'request_vid_and_avinode'
      | 'request_owner'
      | 'account_owner';
    value: unknown;
  };
  [EVENT_NAMES.QUOTES_FILTER_CHANGED]: {
    name:
      | 'created_date'
      | 'leg_dept_date'
      | 'aircraft'
      | 'aircraft_type'
      | 'arr_airport'
      | 'dept_airport'
      | 'request_vid_and_avinode'
      | 'quote_vid';
    value: unknown;
  };
  [EVENT_NAMES.REQUEST_MARK_AS_PREFERRED]: {
    value: boolean;
  };
  [EVENT_NAMES.REQUEST_MARK_AS_DELETED]: {
    value: boolean;
  };
  [EVENT_NAMES.QUOTE_FORM_INTERACTIONS]: {
    field: string;
  };
  [EVENT_NAMES.QUOTE_FORM_NEW_LEG]: undefined;
  [EVENT_NAMES.QUOTE_FORM_EDIT_LEG]: undefined;
  [EVENT_NAMES.QUOTE_FORM_SAVE_LEG]: undefined;
  [EVENT_NAMES.QUOTE_FORM_DELETE_LEG]: undefined;
  [EVENT_NAMES.QUOTE_SUBMITTED]: {
    ownQuotes: number;
    otherQuotes: number;
  };
  [EVENT_NAMES.QUOTE_MARK_AS_UNAVAILABLE]: undefined;
}
