import React, { useEffect, useState } from 'react';
import _first from 'lodash/first';
import { useDebounce } from 'use-debounce';
import { AirportFindDestination } from 'fortune-client';
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import useFindAirports from '~/api/useFindAirports';

export type AirportAutocompleteFindDestination = Omit<AirportFindDestination, 'city' | 'airportName'>;
const getSearchText = (option: AirportAutocompleteFindDestination) => {
  const parts = [];
  if (option.icao) parts.push(option.icao);
  if (option.displayText) parts.push(option.displayText);
  return parts.length ? parts.join(', ') : '';
};

export interface AirportAutocompleteProps {
  value?: AirportAutocompleteFindDestination;
  airportCode?: string | null;
  onAirportSelect: (code: string | null, destination: AirportAutocompleteFindDestination | null) => void;
  id: string;
  label: string;
  error?: string;
}

const AirportAutocomplete: React.FC<AirportAutocompleteProps> = ({
  value = null,
  airportCode,
  onAirportSelect,
  id,
  label,
  error,
}) => {
  const [searchText, setSearchText] = useState('');
  const [lastSelectedAirport, setLastSelectedAirport] = useState<AirportAutocompleteFindDestination | null>(value);
  const [debouncedValue] = useDebounce(searchText, 500);

  const airportsQuery = useFindAirports(debouncedValue);
  const initialAirportQuery = useFindAirports(airportCode || '', {
    disabled: !airportCode || !!lastSelectedAirport,
  });

  useEffect(() => {
    if (airportCode === null) {
      setSearchText('');
      setLastSelectedAirport(null);
    }

    if (!initialAirportQuery.data?.length || !airportCode) return;
    const destination =
      initialAirportQuery.data.find((el) => el.code === airportCode) || _first(initialAirportQuery.data) || null;
    setLastSelectedAirport(destination);
  }, [initialAirportQuery.data, airportCode]);

  return (
    <Autocomplete
      fullWidth
      id={id}
      loading={airportsQuery.isPending}
      options={airportsQuery.data || []}
      filterOptions={(options) => options}
      getOptionLabel={getSearchText}
      isOptionEqualToValue={(option, value) => option.code === value.code}
      blurOnSelect={true}
      value={lastSelectedAirport}
      onChange={(_event, airport) => {
        airport = airport || null;
        setLastSelectedAirport(airport);
        onAirportSelect(airport?.code || null, airport);
      }}
      inputValue={lastSelectedAirport ? getSearchText(lastSelectedAirport) : searchText}
      onInputChange={(_event, newInputValue, type) => {
        if (type !== 'reset') {
          setLastSelectedAirport(null);
          setSearchText(newInputValue);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={Boolean(error)}
          helperText={error}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {airportsQuery.isPending ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
        />
      )}
    />
  );
};

export default AirportAutocomplete;
