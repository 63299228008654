import { FortuneClientInstance, OperatorQuoteResource, PendingRequest } from 'fortune-client';
import operatorQuoteFactory from '../utils/operatorQuoteFactory';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useFortuneClient from '~/hooks/useFortuneClient';
import { PendingRequestDetailsQueryKey } from '~/api/usePendingRequest';
import { useParams } from 'react-router-dom';

const markQuoteUnavailable =
  (fortuneClient?: FortuneClientInstance) =>
  async ({ operatorQuoteId }: { operatorQuoteId: string }) => {
    const operatorQuote = operatorQuoteFactory({ fortuneClient });

    return await operatorQuote.updateQuote(operatorQuoteId, [
      {
        op: 'replace',
        path: '/operator-quotes/0/hiddenFromQuotesGrid',
        value: true,
      },
    ]);
  };

const useMarkQuoteUnavailable = () => {
  const queryClient = useQueryClient();
  const { vid } = useParams<{ vid: string }>();
  const { fortuneClient } = useFortuneClient();
  return useMutation({
    mutationFn: markQuoteUnavailable(fortuneClient),
    onSuccess: (operatorQuote: OperatorQuoteResource) => {
      if (!vid) return;

      queryClient.setQueryData<PendingRequest | undefined>(PendingRequestDetailsQueryKey.get(vid), (request) => {
        if (!request) return request;

        return {
          ...request,
          quotesInfo: request.quotesInfo.map((quote) => {
            if (quote.id !== operatorQuote.id) return quote;
            return {
              ...quote,
              availableActions: quote.availableActions.filter((action) => action !== 'mark-as-unavailable'),
              hidden: true,
              status: undefined,
            };
          }),
        };
      });
    },
  });
};

export default useMarkQuoteUnavailable;
