import React from 'react';
import _isEmpty from 'lodash/isEmpty';
import dateUtils from '../../../utils/dateUtils';
import { IQuoteFormSchema, IQuoteFormSchemaEditLeg, OperatorQuoteDataLeg } from './';
import { FormikProps, FormikTouched } from 'formik';
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import AirportAutocomplete, { AirportAutocompleteFindDestination } from '~/components/filters/AirportAutocomplete';
import { DateTimePicker, TimeField, TimeValidationError } from '@mui/x-date-pickers';
import { assertIsDefined } from '~/types/typeGuards';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';
import { addMinutes, getHours, getMinutes, startOfDay } from 'date-fns';

export interface QuoteLegFormProps {
  onSave: (leg: OperatorQuoteDataLeg) => void | Promise<void>;
  onCancel: () => void;
  formik: FormikProps<IQuoteFormSchema & { editLeg: IQuoteFormSchemaEditLeg }>;
}

const mapToAutocompleteAirport = (
  airport: IQuoteFormSchemaEditLeg['deptAirport'],
): AirportAutocompleteFindDestination | undefined => {
  if (!airport) return undefined;
  return {
    code: airport.id,
    displayText: airport.displayInfo,
    timeZone: airport.timeZone || '',
    country: '',
    type: 'Airport',
    icao: airport.icao,
  };
};

const mapFromAutocompleteAirport = (
  airport: AirportAutocompleteFindDestination | null,
): IQuoteFormSchemaEditLeg['deptAirport'] => {
  if (!airport) return null;
  const result: IQuoteFormSchemaEditLeg['deptAirport'] = {
    id: airport.code,
    airportCode: airport.code,
    displayInfo: airport.displayText,
    timeZone: airport.timeZone,
    icao: airport.icao,
  };
  return result;
};

const QuoteLegForm: React.FC<QuoteLegFormProps> = ({ formik, onSave, onCancel }) => {
  const values = formik.values.editLeg;
  const touched = formik.touched.editLeg || {};
  const errors = formik.errors.editLeg || {};

  const setAirport =
    (key: keyof IQuoteFormSchemaEditLeg) =>
    (_code: string | null, airport: AirportAutocompleteFindDestination | null) => {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
        field: `editLeg.${key}`,
      });
      formik.setFieldValue(`editLeg.${key}`, mapFromAutocompleteAirport(airport));
    };

  const setDeptDate = (date: Date | null) => {
    analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
      field: 'editLeg.deptDate',
    });
    const dateObj = date ? dateUtils.toObject(date) : null;

    formik.setFieldValue('editLeg.deptDate', dateObj || null);
  };

  const onSaveLeg = async () => {
    if (_isEmpty(errors)) {
      assertIsDefined(values.deptDate, 'Dept date must be defined');
      assertIsDefined(values.arrAirport, 'Arr airport must be defined');
      assertIsDefined(values.deptAirport, 'Dept airport must be defined');

      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_SAVE_LEG);

      await onSave({
        deptDate: values.deptDate,
        flightTime: values.flightTime,
        arrAirport: values.arrAirport,
        passengers: values.passengers,
        deptAirport: values.deptAirport,
      } as OperatorQuoteDataLeg);
      formik.setTouched({ ...formik.touched, editLeg: {} }, true);
    } else {
      const fields = Object.keys(values) as (keyof IQuoteFormSchemaEditLeg)[];
      const touched = fields.reduce((memo, field) => {
        memo[field] = true;
        return memo;
      }, {} as FormikTouched<IQuoteFormSchemaEditLeg>);
      formik.setTouched({ ...formik.touched, editLeg: touched }, true);
    }
  };

  const handleChangeFlightTime: FieldChangeHandler<Date | null, TimeValidationError> = (date, options) => {
    analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
      field: 'editLeg.flightTime',
    });

    if (options.validationError) return;

    if (!date) {
      formik.setFieldValue('editLeg.flightTime', null);
      return;
    }

    const hours = getHours(date);
    const minutes = getMinutes(date);
    formik.setFieldValue('editLeg.flightTime', hours * 60 + minutes);
  };

  const handleFlightTimeBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (event) => {
    if (event.target.value.includes('m') || event.target.value.includes('h'))
      formik.setFieldValue('editLeg.flightTime', null);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { name },
    } = event;
    if (name) {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
        field: name,
      });
    }
    formik.handleChange(event);
  };

  const flightTime = values.flightTime ? addMinutes(startOfDay(new Date()), values.flightTime) : null;

  return (
    <Paper sx={{ p: 3 }} elevation={8}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">{values.isNew ? 'New' : 'Edit'} leg</Typography>
        </Grid>
        <Grid item xs={6}>
          <AirportAutocomplete
            id="dept-airport-leg-form"
            label="Dept. Airport"
            value={mapToAutocompleteAirport(values.deptAirport)}
            onAirportSelect={setAirport('deptAirport')}
            error={(touched.deptAirport && errors.deptAirport) || undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <AirportAutocomplete
            id="arr-airport-leg-form"
            label="Arr. Airport"
            value={mapToAutocompleteAirport(values.arrAirport)}
            onAirportSelect={setAirport('arrAirport')}
            error={(touched.arrAirport && errors.arrAirport) || undefined}
          />
        </Grid>
        <Grid item xs={6}>
          <DateTimePicker<Date>
            disablePast
            format="yyyy-MMM-dd HH:mm"
            value={values.deptDate ? dateUtils.toDate(values.deptDate) : null}
            onChange={setDeptDate}
            slotProps={{
              textField: {
                label: 'Local Dept. Date',
                helperText: touched.deptDate && errors.deptDate,
                error: Boolean(touched.deptDate && errors.deptDate),
              },
            }}
            ampm={false}
          />
        </Grid>
        <Grid item xs={3}>
          <TimeField
            size="small"
            ampm={false}
            format="HH:mm"
            label="Flight Time (hh:mm)"
            sx={{ textAlign: 'right' }}
            value={flightTime}
            onChange={handleChangeFlightTime}
            onBlur={handleFlightTimeBlur}
            FormHelperTextProps={{
              error: Boolean(touched.flightTime && errors.flightTime),
            }}
            helperText={touched.flightTime && errors.flightTime}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            label="Passengers"
            name={'editLeg.passengers'}
            type="number"
            size="small"
            fullWidth
            value={values.passengers || ''}
            onChange={handleChange}
            error={Boolean(touched.passengers && errors.passengers)}
            helperText={touched.passengers && errors.passengers}
          />
        </Grid>
        <Grid item container spacing={2} sx={{ justifyContent: 'flex-end' }}>
          <Grid item>
            <Button variant="contained" color="secondary" size="small" onClick={onCancel}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" size="small" onClick={onSaveLeg}>
              Apply
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuoteLegForm;
