import { Button, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react';
import dateUtils from '../../../utils/dateUtils';
import QuoteLegForm, { QuoteLegFormProps } from './QuoteLegForm';
import _cloneDeep from 'lodash/cloneDeep';
import _last from 'lodash/last';
import moment from 'moment-timezone';
import { IQuoteFormSchema, IQuoteFormSchemaEditLeg, OperatorQuoteDataLeg } from './';
import { FormikErrors, FormikProps } from 'formik';
import { Delete, Edit } from '@mui/icons-material';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES } from '~/analytics/types';
import { AirportResource } from 'fortune-client';
import useDataTestId from '~/hooks/useDataTestId';
import { TimeField, TimeValidationError } from '@mui/x-date-pickers';
import { addMinutes, getHours, getMinutes, startOfDay } from 'date-fns';
import { FieldChangeHandler } from '@mui/x-date-pickers/internals';

interface QuoteLegsProps {
  formik: FormikProps<IQuoteFormSchema>;
  dataTestId?: string;
}

const QuoteLegFormRow: React.FC<QuoteLegsProps & { index: number }> = ({ formik, index }) => {
  const closeEditLeg = () => {
    formik.setFieldValue('editLeg', null);
    formik.setFieldTouched('editLeg', false);
  };

  const onSaveLeg = (values: OperatorQuoteDataLeg) => {
    const date = moment(dateUtils.toDate(values.deptDate));
    if (values.deptAirport.timeZone) {
      date.tz(values.deptAirport.timeZone, true);
    }
    formik.setFieldValue(`legs.${index}`, {
      ...values,
      deptDate: {
        ...values.deptDate,
        timeZone: date.format('Z'),
      },
    });
    closeEditLeg();
  };

  return (
    <TableRow>
      <TableCell colSpan={7} sx={{ p: 0 }}>
        <QuoteLegForm formik={formik as QuoteLegFormProps['formik']} onSave={onSaveLeg} onCancel={closeEditLeg} />
      </TableCell>
    </TableRow>
  );
};

const QuoteLegs: React.FC<QuoteLegsProps> = ({ formik, dataTestId }) => {
  const dt = useDataTestId(dataTestId);
  const { victorDateToUtcVictorDate } = dateUtils;

  const onDeleteLeg = (index: number) => {
    const legs = formik.values.legs;
    legs.splice(index, 1);

    analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_DELETE_LEG);

    formik.setFieldValue('legs', legs);
  };

  const setEditLeg = (leg?: OperatorQuoteDataLeg, index?: number) => {
    const editLeg: IQuoteFormSchemaEditLeg = leg
      ? _cloneDeep({ ...leg, index })
      : {
          deptDate: null,
          deptAirport: null,
          arrAirport: null,
          passengers: _last(formik.values.legs)?.passengers,
          flightTime: undefined,
          index,
          isNew: true,
        };

    analyticsUtils.trackEvent(leg ? EVENT_NAMES.QUOTE_FORM_EDIT_LEG : EVENT_NAMES.QUOTE_FORM_NEW_LEG);

    formik.setFieldValue('editLeg', editLeg);
  };

  const maxIndex = formik.values.legs.length;
  const isAddingNewLeg = Boolean(formik.values.editLeg && formik.values.editLeg.index === maxIndex);

  const handleChangeFlightTime =
    (index: number): FieldChangeHandler<Date | null, TimeValidationError> =>
    (date, options) => {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTE_FORM_INTERACTIONS, {
        field: 'flightTime',
      });

      if (options.validationError) return;

      if (!date) {
        formik.setFieldValue(`legs.${index}.flightTime`, null);
        return;
      }

      const hours = getHours(date);
      const minutes = getMinutes(date);
      formik.setFieldValue(`legs.${index}.flightTime`, hours * 60 + minutes);
    };

  const handleFlightTimeBlur =
    (index: number): React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> =>
    (event) => {
      if (event.target.value.includes('m') || event.target.value.includes('h'))
        formik.setFieldValue(`legs.${index}.flightTime`, null);
    };

  const getAirportInfo = (airport?: Pick<AirportResource, 'icao' | 'displayInfo'>) => {
    if (!airport) return '';
    if (airport.icao) return `${airport.icao}, ${airport.displayInfo}`;
    return airport.displayInfo;
  };

  return (
    <Stack alignItems="flex-end" spacing={2}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Departure Date (UTC)</TableCell>
            <TableCell>Local Departure Date</TableCell>
            <TableCell>Dept. Airport</TableCell>
            <TableCell>Arr. Airport</TableCell>
            <TableCell>Passengers</TableCell>
            <TableCell>Flight Time (hh:mm)</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {formik.values.legs.map((leg, index) => {
            const testIdPrefix = dt(`leg-${index}`) || '';
            const localDate = leg.deptDate;
            const utcDate = victorDateToUtcVictorDate(leg.deptDate);
            const touched = formik.touched.legs?.[index] || {};
            const errors = (formik.errors.legs?.[index] || {}) as FormikErrors<OperatorQuoteDataLeg>;
            const isEditing = Boolean(formik.values.editLeg?.index === index);
            const flightTime = leg.flightTime ? addMinutes(startOfDay(new Date()), leg.flightTime) : null;
            return (
              <React.Fragment key={index}>
                <TableRow selected={isEditing}>
                  <TableCell data-testid={`${testIdPrefix}-dept-date`}>
                    {utcDate?.date} {utcDate?.time}
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }} data-testid={`${testIdPrefix}-local-dept-date`}>
                    {localDate.date} {localDate.time} ({localDate.timeZone})
                  </TableCell>
                  <TableCell data-testid={`${testIdPrefix}-dept-airport`}>{getAirportInfo(leg.deptAirport)}</TableCell>
                  <TableCell data-testid={`${testIdPrefix}-arr-airport`}>{getAirportInfo(leg.arrAirport)}</TableCell>
                  <TableCell data-testid={`${testIdPrefix}-passengers`}>{leg.passengers}</TableCell>
                  <TableCell>
                    <TimeField
                      size="small"
                      ampm={false}
                      format="HH:mm"
                      name={`legs.${index}.flightTime`}
                      value={flightTime}
                      onBlur={handleFlightTimeBlur(index)}
                      onChange={handleChangeFlightTime(index)}
                      sx={{ textAlign: 'right' }}
                      FormHelperTextProps={{
                        error: Boolean(touched.flightTime && errors.flightTime),
                      }}
                      helperText={touched.flightTime && errors.flightTime}
                      disabled={formik.isSubmitting || isEditing}
                      inputProps={{
                        'data-testid': `${testIdPrefix}-flight-time`,
                      }}
                    />
                  </TableCell>
                  <TableCell sx={{ whiteSpace: 'nowrap' }}>
                    {!isEditing && (
                      <>
                        <IconButton aria-label="edit quote leg" onClick={() => setEditLeg(leg, index)} edge="end">
                          <Edit />
                        </IconButton>
                        <IconButton aria-label="remove quote leg" onClick={() => onDeleteLeg(index)} edge="end">
                          <Delete />
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                </TableRow>
                {isEditing && <QuoteLegFormRow formik={formik} index={index} />}
              </React.Fragment>
            );
          })}
          {isAddingNewLeg && <QuoteLegFormRow formik={formik} index={maxIndex} />}
        </TableBody>
      </Table>
      {!formik.values.editLeg && (
        <Button
          variant="contained"
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => setEditLeg(undefined, maxIndex)}
          size="large"
        >
          + Add
        </Button>
      )}
    </Stack>
  );
};

export default QuoteLegs;
