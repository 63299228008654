import FilterSummary, { ChipOption, getDateRangeChip, IconChip } from '~/components/filters/FilterSummary';
import {
  useFilterAircraft,
  useFilterAircraftType,
  useFilterCreatedDateRange,
  useFilterLegArrivalAirport,
  useFilterLegDeptAirport,
  useFilterLegDeptDateRange,
  useFilterVid,
  useFilterStatus,
} from '~/views/dashboard/QuotesView/hooks/filters';
import { FlightLand, FlightTakeoff } from '@mui/icons-material';
import { getStatusLabel } from '~/views/dashboard/components/QuoteStatus';
import { OperatorQuoteVConnectStatus } from 'fortune-client';

const Summary = () => {
  const [createdDateRange, setCreatedDateRange] = useFilterCreatedDateRange();
  const [legDeptDateRange, setLegDeptDateRange] = useFilterLegDeptDateRange();
  const [aircraft, setAircraft] = useFilterAircraft();
  const [aircraftType, setAircraftType] = useFilterAircraftType();
  const [legDeptAirport, setLegDeptAirport] = useFilterLegDeptAirport();
  const [legArrAirport, setLegArrAirport] = useFilterLegArrivalAirport();
  const [requestVid, setRequestVid] = useFilterVid();
  const [status, setStatus] = useFilterStatus();

  const chipsOptions: (ChipOption | null)[] = [
    {
      label: legDeptAirport ? <IconChip Icon={FlightTakeoff} label={legDeptAirport.displayText} /> : 'From Any Airport',
      enabled: !!legDeptAirport,
      onDelete: () => setLegDeptAirport(null),
    },
    {
      label: legArrAirport ? <IconChip Icon={FlightLand} label={legArrAirport.displayText} /> : 'To Any Airport',
      enabled: !!legArrAirport,
      onDelete: () => setLegArrAirport(null),
    },
    {
      label: aircraft ? `Aircraft ${aircraft}` : 'Any Aircraft',
      enabled: !!aircraft,
      onDelete: () => setAircraft(null),
    },
    {
      label: aircraftType ? `Aircraft type ${aircraftType}` : 'Any Aircraft Type',
      enabled: !!aircraftType,
      onDelete: () => setAircraftType(null),
    },
    getDateRangeChip(createdDateRange, () => setCreatedDateRange(''), 'Any created date'),
    getDateRangeChip(legDeptDateRange, () => setLegDeptDateRange(''), 'Any leg date'),
    {
      label: status ? `${getStatusLabel(status as OperatorQuoteVConnectStatus)} Status` : 'Any Status',
      enabled: !!status,
      onDelete: () => setStatus(null),
    },
  ];

  if (requestVid) {
    chipsOptions.unshift({
      label: requestVid,
      enabled: true,
      onDelete: () => setRequestVid(''),
    });
  }
  return <FilterSummary chipsOptions={chipsOptions} />;
};

export default Summary;
