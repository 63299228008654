import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import _isNil from 'lodash/isNil';
import useExceptionLogger from './useExceptionLogger';

export enum PersistedStateKeys {
  VConnectUserId = 'vconnect_user_id',
  VConnectToken = 'vconnect_access_token',
  VConnectRefreshToken = 'vconnect_refresh_token',
}

type PersistableValueType = number | string | boolean | null | undefined;

export const getPersistedValue = (
  name: PersistedStateKeys,
  defaultValue: PersistableValueType,
): PersistableValueType => {
  const storedValue = localStorage.getItem(name);
  if (_isNil(storedValue)) return defaultValue;

  if (typeof defaultValue === 'number') {
    return Number(storedValue);
  }

  if (typeof defaultValue === 'boolean') {
    return storedValue === 'true';
  }

  return storedValue;
};

const setPersistedValue = (name: PersistedStateKeys, value: PersistableValueType) => {
  if (_isNil(value)) {
    localStorage.removeItem(name);
    return;
  }

  let valueToStore: string;
  if (typeof value === 'number') {
    valueToStore = value.toString();
  } else if (typeof value === 'boolean') {
    valueToStore = value ? 'true' : 'false';
  } else {
    valueToStore = value;
  }

  localStorage.setItem(name, valueToStore);
};

const usePersistedState = <T extends PersistableValueType>(
  name: PersistedStateKeys,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>, () => void] => {
  const logger = useExceptionLogger();
  const initialValue = getPersistedValue(name, defaultValue) as T;
  const [value, setValue] = useState<T>(initialValue);

  const reset = () => {
    localStorage.removeItem(name);
    setValue(defaultValue);
  };

  useEffect(() => {
    try {
      setPersistedValue(name, value);
    } catch (err) {
      logger.captureException(err);
    }
  }, [name, value, logger]);

  return [value, setValue, reset];
};

export default usePersistedState;
