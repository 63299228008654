import useFortuneClient from '~/hooks/useFortuneClient';
import { useQuery } from '@tanstack/react-query';
import { FortuneClientInstance, PendingRequestRequest } from 'fortune-client';
import { assertIsDefined } from '~/types/typeGuards';

export const PendingRequestDetailsQueryKey = {
  all: ['Dashboard', 'PendingRequestDetails'],
  get: (vid?: string) => ['Dashboard', 'PendingRequestDetails', vid],
};

const fetchPendingRequest = async (fortuneClient: FortuneClientInstance, vid?: string) => {
  const apiFilters: Partial<PendingRequestRequest['filter']> = {
    requestVid: vid,
  };

  const {
    'pending-requests': [request],
  } = await fortuneClient.callOperatorCharterRequestPendingRequests(null, {
    filter: apiFilters as PendingRequestRequest['filter'],
    page: 1,
    pageSize: 1,
    sort: 'createdAt',
    decorateWith: 'latestFlights',
  });
  return request;
};

const usePendingRequest = (vid?: string) => {
  const { isLoading, fortuneClient } = useFortuneClient();

  return useQuery({
    queryKey: PendingRequestDetailsQueryKey.get(vid),
    queryFn: () => {
      assertIsDefined(fortuneClient, 'FortuneClient is defined.');
      return fetchPendingRequest(fortuneClient, vid);
    },
    enabled: !isLoading && !!vid,
  });
};

export default usePendingRequest;
