import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { DateRange, DateRangePicker as DateRangePickerComponent } from '~/components/pickers/DateRangePicker';
import { format } from 'date-fns';
import { Clear } from '@mui/icons-material';
import moment from 'moment-timezone';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES, EVENT_PROPERTIES } from '~/analytics/types';
import { isDefined } from '~/types/typeGuards';

import { FilterQueryFnc } from '~/views/dashboard/utils/filters';

interface DateRangePickerProps {
  dateRangeFilterFunc: FilterQueryFnc<string | null>;
  label: string;
  event: EVENT_NAMES.FILTER_CHANGED | EVENT_NAMES.QUOTES_FILTER_CHANGED;
  eventPropName:
    | EVENT_PROPERTIES[EVENT_NAMES.FILTER_CHANGED]['name']
    | EVENT_PROPERTIES[EVENT_NAMES.QUOTES_FILTER_CHANGED]['name'];
}
const DateRangePicker = ({ dateRangeFilterFunc, label, event, eventPropName }: DateRangePickerProps) => {
  const [dateRange, setDateRange] = dateRangeFilterFunc();
  const [open, setOpen] = React.useState(false);

  const toggleDateRange = () => setOpen(!open);
  const rangeDateStr = React.useMemo(() => {
    if (!dateRange) return '';
    return dateRange
      .split('/')
      .filter(isDefined)
      .map((el) => format(new Date(el), 'yyyy-MMM-dd'))
      .join(' - ');
  }, [dateRange]);

  const wrapSetDateRange = (dateRange: DateRange) => {
    const startDateStr = dateRange.startDate ? format(dateRange.startDate, 'yyyy-MM-dd') : null;
    const endDateStr = dateRange.endDate ? format(dateRange.endDate, 'yyyy-MM-dd') : null;
    const value = !startDateStr || !endDateStr ? null : `${startDateStr}/${endDateStr}`;
    analyticsUtils.trackEvent(event, { name: eventPropName, value: value });
    setDateRange(value);
    setOpen(false);
  };

  const parts = dateRange ? dateRange.split('/') : null;
  const value: DateRange | undefined =
    parts && parts.length === 2
      ? {
          startDate: moment(parts[0]).toDate(),
          endDate: moment(parts[1]).toDate(),
        }
      : undefined;

  return (
    <>
      <TextField
        label={label}
        value={rangeDateStr}
        type="text"
        onClick={toggleDateRange}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="clear date range input"
                onClick={(e) => {
                  e.stopPropagation();
                  wrapSetDateRange({});
                }}
                edge="end"
              >
                <Clear />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <DateRangePickerComponent
        open={open}
        wrapperSx={{ position: 'absolute' }}
        toggle={toggleDateRange}
        onChange={wrapSetDateRange}
        value={value}
      />
    </>
  );
};

export default DateRangePicker;
