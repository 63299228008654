import FingerprintJS from '@fingerprintjs/fingerprintjs';

let visitorIdPromise: Promise<string | null> | null = null;

export const getVisitorId = async () => {
  if (!visitorIdPromise) {
    let visitorId: string | null = null;
    visitorIdPromise = (async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        visitorId = result.visitorId;
      } catch (err) {
        console.trace(err);
      }
      return visitorId;
    })();
  }
  return await visitorIdPromise;
};
