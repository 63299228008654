import { AircraftTypeResource } from 'fortune-client';
import { Autocomplete, Box, CircularProgress, TextField } from '@mui/material';
import useFindLoggedUserAircrafts from '~/api/useFindLoggedUserAircrafts';
import { useMemo } from 'react';

export interface AircraftTypeAutocompleteProps {
  onTypeChanged: (type: string | null) => void;
  disabled?: boolean;
  helperText?: string;
  error?: boolean;
  value?: AircraftTypeResource['id'] | null;
}

const AircraftTypeAutocomplete: React.FC<AircraftTypeAutocompleteProps> = (props) => {
  const { onTypeChanged, error, disabled, helperText, value } = props;
  const { data: aircraftOptions, isPending } = useFindLoggedUserAircrafts();
  const types = useMemo(() => {
    if (!aircraftOptions) return [];
    return [...new Set(aircraftOptions.map((aircraft) => aircraft.links.type))];
  }, [aircraftOptions]);
  const selectedType = types.find((type) => type === value);

  return (
    <Autocomplete
      value={selectedType || null}
      options={types}
      onChange={(e, val) => {
        onTypeChanged(val || null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          disabled={disabled}
          label="Aircraft Type"
          InputProps={{
            ...params.InputProps,
            startAdornment: isPending ? (
              <Box>
                <CircularProgress size={20} />
              </Box>
            ) : null,
          }}
        />
      )}
    />
  );
};

export default AircraftTypeAutocomplete;
