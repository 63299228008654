import { IconButton, InputAdornment, TextField } from '@mui/material';
import { Clear } from '@mui/icons-material';
import { FilterQueryFnc, useDebouncedFilter } from '~/views/dashboard/utils/filters';
import { EVENT_NAMES, EVENT_PROPERTIES } from '~/analytics/types';

interface RequestVidFilterProps {
  filterFunc: FilterQueryFnc<string>;
  event: EVENT_NAMES.FILTER_CHANGED | EVENT_NAMES.QUOTES_FILTER_CHANGED;
  eventPropName:
    | EVENT_PROPERTIES[EVENT_NAMES.FILTER_CHANGED]['name']
    | EVENT_PROPERTIES[EVENT_NAMES.QUOTES_FILTER_CHANGED]['name'];
}

const RequestVidFilter = ({ filterFunc, event, eventPropName }: RequestVidFilterProps) => {
  const [requestVid, setRequestVid] = useDebouncedFilter(filterFunc, event, eventPropName);

  return (
    <TextField
      label="Request Number"
      value={requestVid}
      onChange={(event) => setRequestVid(event.target.value)}
      type="text"
      fullWidth
      InputProps={{
        endAdornment: requestVid ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear request number input"
              onClick={(e) => {
                e.stopPropagation();
                setRequestVid('');
              }}
              edge="end"
            >
              <Clear />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
    />
  );
};

export default RequestVidFilter;
