import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { isDefined } from '~/types/typeGuards';
import _isString from 'lodash/isString';

export enum SortingDirection {
  Desc = 'desc',
  Asc = 'asc',
}
export interface SortingItem {
  key: string;
  defaultDirection: SortingDirection;
}

export interface TableHeaderSortItem {
  label: string;
  sort?: SortingItem;
}

export const isSortingDirection = (val: unknown): val is SortingDirection => {
  if (!isDefined(val)) return false;
  if (!_isString(val)) return false;
  return Object.values(SortingDirection)
    .map((el) => el.toString())
    .includes(val);
};

export const useTableSorting = (defaultSort: SortingItem) => {
  const [sorting, setSorting] = useQueryParams({
    sort: withDefault(StringParam, defaultSort.key),
    direction: withDefault(StringParam, defaultSort.defaultDirection),
  });

  const getOnSortHandler = (item: TableHeaderSortItem) => () => {
    const switchToDesc =
      sorting.sort === item.sort?.key
        ? isSortingDirection(sorting.direction) && sorting.direction === SortingDirection.Asc
        : item.sort?.defaultDirection !== SortingDirection.Asc;
    setSorting({
      sort: item.sort?.key,
      direction: switchToDesc ? SortingDirection.Desc : SortingDirection.Asc,
    });
  };

  return { sorting, getOnSortHandler };
};

export const formatSorting = ({ sort, direction }: { sort: string; direction: SortingDirection }) => {
  const key = sort;
  if (direction === SortingDirection.Desc) {
    return `-${key}`;
  }
  return key;
};

export const sortAlphabetically = (a: string | null, b: string | null) => {
  if (a === b)
    // identical? return 0
    return 0;
  else if (a === null)
    // a is null? last
    return 1;
  else if (b === null)
    // b is null? last
    return -1;
  // compare, negate if descending
  else return a.localeCompare(b);
};

export const sortObjectsAlphabetically =
  <T>(getFieldValue: (val: T) => string | null) =>
  (a: T, b: T) =>
    sortAlphabetically(getFieldValue(a), getFieldValue(b));
