import { VConnectFilterSetting } from 'fortune-client';
import { QueryParamConfig } from 'serialize-query-params/src/types';
import { UrlUpdateType } from 'use-query-params';
import { EVENT_NAMES, EVENT_PROPERTIES } from '~/analytics/types';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { analyticsUtils } from '~/analytics/utils';

export enum RequestRatingHeat {
  Cold = 'COLD',
  Warm = 'WARM',
  Hot = 'HOT',
}

export const convertIndexToRatingRate = (index: number) => {
  if (index === 0) return RequestRatingHeat.Cold;
  if (index === 1) return RequestRatingHeat.Warm;
  if (index === 2) return RequestRatingHeat.Hot;
  throw new TypeError();
};

export const mapToVConnectFilterSettings = <T extends {}>(filters: T) => {
  return Object.keys(filters).reduce<VConnectFilterSetting[]>((memo, key) => {
    memo.push({ key, value: filters[key as keyof T] });
    return memo;
  }, []);
};

export const mapToFormFilters = <T extends {}>(settings: VConnectFilterSetting[]): Partial<T> => {
  const result = settings.reduce<Record<string, VConnectFilterSetting['value']>>((memo, item) => {
    memo[item.key] = item.value;
    return memo;
  }, {});
  return result as Partial<T>;
};

export interface FilterAirport {
  code: string;
  displayText: string;
}

export const AirportParam: QueryParamConfig<FilterAirport | null | undefined> = {
  decode: (val) => {
    if (val === undefined) return undefined;
    val = Array.isArray(val) ? val[0] : val;
    if (!val) return null;
    const [code, displayText] = val.split('-');
    return {
      code,
      displayText,
    };
  },
  encode: (val) => {
    return val ? `${val.code}-${val.displayText}` : null;
  },
};

export type NewValueType<D> = D | ((latestValue: D) => D);
export type FilterQueryFnc<T> = () => [T, (newValue: NewValueType<T>, updateType?: UrlUpdateType) => void];
export const useDebouncedFilter = <T extends string | number | boolean | null | undefined>(
  filterFunc: FilterQueryFnc<T>,
  event: EVENT_NAMES.FILTER_CHANGED | EVENT_NAMES.QUOTES_FILTER_CHANGED,
  eventName:
    | EVENT_PROPERTIES[EVENT_NAMES.FILTER_CHANGED]['name']
    | EVENT_PROPERTIES[EVENT_NAMES.QUOTES_FILTER_CHANGED]['name'],
  delay = 500,
): [T, React.Dispatch<T>] => {
  const [filterValue, changeFilterValue] = filterFunc();
  const [value, setValue] = useState<T>(filterValue);
  const [debouncedValue] = useDebounce(value, delay);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue, setValue]);

  useEffect(() => {
    analyticsUtils.trackEvent(event, { name: eventName, value: debouncedValue });
    changeFilterValue(debouncedValue);
  }, [debouncedValue, changeFilterValue, event, eventName]);

  return [value, setValue];
};
