import _isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';
import useLoggedUser from '~/api/useLoggedUser';
import useLastFilterSettings from '~/views/dashboard/PendingRequestsView/api/useLastFilterSettings';
import useCreateVConnectFilter from '~/views/dashboard/PendingRequestsView/api/useCreateVConnectFilter';
import { PendingRequestsFilters, usePendingRequestsFilters } from '~/views/dashboard/PendingRequestsView/hooks/filters';
import { OperatorQuotesFilters, useOperatorQuotesFilters } from '~/views/dashboard/QuotesView/hooks/filters';
import { VConnectFilterResource } from 'fortune-client';

const FILTER_HOOKS: Record<VConnectFilterResource['source'], () => PendingRequestsFilters | OperatorQuotesFilters> = {
  quotes: useOperatorQuotesFilters,
  requests: usePendingRequestsFilters,
};

const useFiltersTracking = (numberOfResults: number, isLoading: boolean, source: VConnectFilterResource['source']) => {
  const filters = FILTER_HOOKS[source]();

  const { mutateAsync: createVConnectFilter } = useCreateVConnectFilter(source);
  const { data: lastFilterSettings, isPending } = useLastFilterSettings(source);
  const lastSavedFilterRef = useRef(lastFilterSettings || null);

  useEffect(() => {
    if (!isPending && lastFilterSettings) {
      lastSavedFilterRef.current = lastFilterSettings;
    }
  }, [lastFilterSettings, isPending]);

  const { data } = useLoggedUser();
  const user = data?.user;

  useEffect(() => {
    if (!isLoading && !isPending && !_isEqual(lastSavedFilterRef.current, filters)) {
      lastSavedFilterRef.current = filters;
      createVConnectFilter({
        filters,
        numberOfResults,
      });
    }
  }, [numberOfResults, filters, source, user, isLoading, createVConnectFilter, isPending]);

  return null;
};

export default useFiltersTracking;
