import { styled } from '@mui/material/styles';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { THEME_COLORS } from '~/theme/palette';
import { OperatorQuoteVConnectStatus } from 'fortune-client';
import React from 'react';
import { NotInterested, Warning } from '@mui/icons-material';
import _capitalize from 'lodash/capitalize';

interface StatusCircleProps {
  status: OperatorQuoteVConnectStatus;
}

interface StatusType {
  label?: string;
  color: string;
  stage?: string;
}

export const getStatusLabel = (status: OperatorQuoteVConnectStatus) => {
  return STATUSES_MAP[status].label || _capitalize(status);
};

export const STATUSES_MAP: Record<OperatorQuoteVConnectStatus, StatusType> = {
  submitted: {
    stage: 'Request',
    color: THEME_COLORS.grey,
  },
  booked: {
    stage: 'Booking',
    color: THEME_COLORS.green,
  },
  visible: {
    stage: 'Request',
    color: THEME_COLORS.black,
  },
  complete: {
    stage: 'Booking',
    color: THEME_COLORS.lightBlue,
  },
  'closed-lost': {
    stage: 'Booking',
    color: THEME_COLORS.red,
    label: 'Closed Lost',
  },
  cancelled: {
    stage: 'Booking',
    color: THEME_COLORS.red,
  },
  closed: {
    stage: 'Request',
    color: THEME_COLORS.orange,
  },
  unavailable: {
    stage: 'Request',
    color: THEME_COLORS.amber,
  },
};

const StatusCircle = styled(Box)<StatusCircleProps>(({ theme, status }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3),
  borderRadius: theme.spacing(3),
  backgroundColor: STATUSES_MAP[status].color,
}));

const getStatusIcon = (status?: OperatorQuoteVConnectStatus, requote?: boolean) => {
  if (!status) return <NotInterested sx={{ color: THEME_COLORS.grey }} />;
  if (requote)
    return (
      <Tooltip title={'Requote required'}>
        <Warning color="warning" />
      </Tooltip>
    );
  return <StatusCircle status={status} />;
};

interface QuoteStatusProps {
  status?: OperatorQuoteVConnectStatus;
  noStatusLabel?: string;
  requote?: boolean;
}
const QuoteStatus = ({ status, noStatusLabel, requote }: QuoteStatusProps) => {
  return (
    <Stack direction="row" spacing={1}>
      {getStatusIcon(status, requote)}
      <Typography variant="body2">{status ? getStatusLabel(status) : noStatusLabel}</Typography>
    </Stack>
  );
};
export default QuoteStatus;
