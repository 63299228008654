import _isNil from 'lodash/isNil';
import { useQuery } from '@tanstack/react-query';
import { AirportFindDestination, FortuneClientInstance } from 'fortune-client';
import useFortuneClient from '../hooks/useFortuneClient';
import { assertIsDefined } from '~/types/typeGuards';

export const FindAirportsQueryKey = (searchText: string) => ['Shared', 'FindAirports', searchText];

const fetchAirports = async (fortuneClient: FortuneClientInstance, searchText: string) => {
  if (!searchText) return [];
  const { destinations } = await fortuneClient.callAirportFind(null, {
    searchText,
  });
  return destinations.reduce<AirportFindDestination[]>((acc, el) => {
    return acc.concat([el], el.children || []);
  }, []);
};

const useFindAirports = (searchText: string, options: { disabled: boolean } = { disabled: false }) => {
  const { isLoading, fortuneClient } = useFortuneClient();
  return useQuery({
    queryKey: FindAirportsQueryKey(searchText),
    queryFn: () => {
      assertIsDefined(fortuneClient, 'Fortune client must be defined and ready');
      return fetchAirports(fortuneClient, searchText);
    },
    enabled: !isLoading && !_isNil(searchText) && !options.disabled,
  });
};

export default useFindAirports;
