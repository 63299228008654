import { API_URL, OAUTH2_CLIENT_ID, OAUTH2_CLIENT_SECRET } from '~/config';
import { FortuneApiError } from 'fortune-client';
import { getVisitorId } from '~/utils/fingerprint';

export interface LoginSuccess {
  tokenType: 'Basic' | 'Bearer';
  accessToken: string;
  refreshToken: string;
}

export interface LoginError {
  errors?: FortuneApiError[];
}
export const isLoginSuccess = (data: LoginSuccess | LoginError): data is LoginSuccess => {
  return !!(data as LoginSuccess).accessToken;
};

interface BaseLoginPayload {
  grantType: string;
  clientId: string;
  clientSecret: string;
}

interface PasswordLoginPayload extends BaseLoginPayload {
  grantType: 'password';
  username: string;
  password: string;
}

interface RefreshTokenLoginPayload extends BaseLoginPayload {
  grantType: 'refresh_token';
  refreshToken: string;
}

interface MfaTokenLoginPayload extends BaseLoginPayload {
  grantType: 'mfa_token';
  username: string;
  password: string;
}

interface MfaOtpLoginPayload extends BaseLoginPayload {
  grantType: 'mfa_otp';
  otp: string;
  otpId: string;
  token: string;
}

const baseLogin = async (
  body: PasswordLoginPayload | RefreshTokenLoginPayload | MfaTokenLoginPayload | MfaOtpLoginPayload,
): Promise<LoginSuccess | LoginError> => {
  const visitorId = await getVisitorId();

  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set('Accept', 'application/json');
  requestHeaders.set('Content-Type', 'application/json');
  if (visitorId) {
    requestHeaders.set('x-device-id', visitorId);
  }

  const tokenRes = await fetch(`${API_URL}/oauth2.0/token`, {
    method: 'POST',
    headers: requestHeaders,
    body: JSON.stringify(body),
  });

  if (tokenRes.status === 201) {
    return (await tokenRes.json()) as LoginSuccess;
  } else {
    return (await tokenRes.json()) as LoginError;
  }
};

export const loginByPassword = async (username: string, password: string) => {
  return await baseLogin({
    grantType: 'password',
    clientId: OAUTH2_CLIENT_ID,
    clientSecret: OAUTH2_CLIENT_SECRET,
    username,
    password,
  });
};

export const loginByRefreshToken = async (refreshToken: string) => {
  return await baseLogin({
    grantType: 'refresh_token',
    clientId: OAUTH2_CLIENT_ID,
    clientSecret: OAUTH2_CLIENT_SECRET,
    refreshToken,
  });
};

export const loginAsApplication = (): Promise<LoginSuccess> => {
  return Promise.resolve({
    tokenType: 'Basic',
    accessToken: btoa(`${OAUTH2_CLIENT_ID}:${OAUTH2_CLIENT_SECRET}`),
    refreshToken: '',
  });
};

export const mfaLoginByPassword = async (username: string, password: string) => {
  return await baseLogin({
    grantType: 'mfa_token',
    clientId: OAUTH2_CLIENT_ID,
    clientSecret: OAUTH2_CLIENT_SECRET,
    username,
    password,
  });
};

export const mfaLoginByOtp = async (otp: string, otpId: string, token: string) => {
  return await baseLogin({
    grantType: 'mfa_otp',
    clientId: OAUTH2_CLIENT_ID,
    clientSecret: OAUTH2_CLIENT_SECRET,
    otp,
    otpId,
    token,
  });
};
