import { FortuneClientInstance, VConnectFilterResource } from 'fortune-client';
import _first from 'lodash/first';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useFortuneClient from '~/hooks/useFortuneClient';
import { PendingRequestsFilters } from '../hooks/filters';
import { assertIsDefined } from '~/types/typeGuards';
import { FilterSettingsKey } from '~/views/dashboard/PendingRequestsView/api/useLastFilterSettings';
import { mapToFormFilters, mapToVConnectFilterSettings } from '~/views/dashboard/utils/filters';
import { OperatorQuotesFilters } from '~/views/dashboard/QuotesView/hooks/filters';

const createVConnectFilter =
  ({ fortuneClient, source }: { fortuneClient?: FortuneClientInstance; source: VConnectFilterResource['source'] }) =>
  async ({
    numberOfResults,
    filters,
  }: {
    numberOfResults: number;
    filters: PendingRequestsFilters | OperatorQuotesFilters;
  }) => {
    assertIsDefined(fortuneClient, 'Fortune client must be defined.');

    return await fortuneClient.createVConnectFilter({
      settings: mapToVConnectFilterSettings(filters),
      numberOfResults,
      source,
    });
  };

const useCreateVConnectFilter = (source: VConnectFilterResource['source']) => {
  const queryClient = useQueryClient();
  const { fortuneClient } = useFortuneClient();

  return useMutation({
    mutationFn: createVConnectFilter({ fortuneClient, source }),
    onSuccess: (respData: { 'v-connect-filters': VConnectFilterResource[] }) => {
      const filter = _first(respData['v-connect-filters']);
      if (!filter) return;
      queryClient.setQueryData<Partial<PendingRequestsFilters>>(FilterSettingsKey.get(source), () => {
        return mapToFormFilters(filter.settings);
      });
    },
  });
};

export default useCreateVConnectFilter;
