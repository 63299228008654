import AirportAutocomplete, { AirportAutocompleteFindDestination } from '~/components/filters/AirportAutocomplete';
import { analyticsUtils } from '~/analytics/utils';
import { EVENT_NAMES, EVENT_PROPERTIES } from '~/analytics/types';
import React, { useCallback } from 'react';
import { FilterAirport, FilterQueryFnc } from '~/views/dashboard/utils/filters';

interface AirportFilterProps {
  id: string;
  label: string;
  airportFilterFunc: FilterQueryFnc<FilterAirport | null>;
  eventName: EVENT_PROPERTIES[EVENT_NAMES.QUOTES_FILTER_CHANGED]['name'];
}

export const AirportFilter: React.FC<AirportFilterProps> = ({ label, id, airportFilterFunc, eventName }) => {
  const [airport, setAirport] = airportFilterFunc();

  const onAirportSelect = useCallback(
    (code: string | null, destination: AirportAutocompleteFindDestination | null) => {
      analyticsUtils.trackEvent(EVENT_NAMES.QUOTES_FILTER_CHANGED, { name: eventName, value: code });
      setAirport(code ? { code, displayText: destination?.icao || code } : null);
    },
    [setAirport, eventName],
  );

  return (
    <AirportAutocomplete
      label={label}
      id={id}
      airportCode={airport ? airport.code : null}
      onAirportSelect={onAirportSelect}
    />
  );
};

export default AirportFilter;
